.TzAgencyInner {
  display: flex;
  background: #ffffff;
  .box-shadow(0 2px 3px 0 rgba(0, 0, 0, 0.1));
  margin-bottom: 40px;
  align-items: stretch;
  border: 1px solid #ffffff;
  flex-wrap: wrap;
  .TzAgencyMedia {
    @media(max-width: 991px){
      padding-top: 15px;
    }
    .tz_portfolio_plus_image {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
  }
  .TzExtraFields {
    background: #f4f5f8;
    padding: 30px 15px;
    .tz_extrafield-value {
      font-size: 12px;
      color: #565656;
    }
  }
  .TzAgencyDescription {
    padding: 30px 15px;
  }
  .TzTitle {
    margin: 0 0 10px;
    font-size: inherit;
    a {
      font-size: 16px;
      font-family: 'Montserrat', sans-serif, Arial, sans-serif;
      font-weight: bold;
      color: #222222;
      display: inline-block;
      position: relative;
      padding-right: 20px;
      &:hover {
        text-decoration: none;
        color: #ff5400;
      }
      &:before {
        background: #ff5400 none repeat scroll 0 0;
        content: "";
        display: block;
        height: 1px;
        left: 100%;
        margin-top: -3px;
        position: absolute;
        top: 50%;
        width: 30px;
      }
      &:after {
        background: #ff5400 none repeat scroll 0 0;
        content: "";
        display: block;
        height: 1px;
        left: 100%;
        margin-top: 4px;
        position: absolute;
        top: 50%;
        width: 45px;
      }
    }
  }
  .TzIntrotext {
    p {
      font-size: 12px;
      margin: 0 0 25px;
      line-height: 1.5em;
      color: #565656;
    }
  }
  .TzReadmore {
    font-size: 11px;
    font-family: 'Montserrat', sans-serif, Arial, sans-serif;
    color: #ffffff;
    text-transform: uppercase;
    background: #222222;
    display: inline-block;
    margin: 0;
    padding: 7px 20px;
    .transition(all 0.3s ease 0.01s);
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    &:hover {
      color: #ffffff;
      background: #ff5400;
      text-decoration: none;
    }
  }
  .TzExtraFields {
    .TzExtrafield-item {
      display: flex;
      padding: 0 0 5px;
      .right {
        flex: 1;
        font-size: 12px;
        color: #565656;
        text-align: left;
        * {
          color: #565656;
          font-size: 12px;
          line-height: 2em;
        }
        a {
          &:hover {
            color: #565656;
            text-decoration: none;
          }
        }
      }
      .left {
        width: 30px;
        color: #565656;
      }
    }
  }
}

.TzAgencies {
  @media (max-width: 991px) {
    margin-bottom: 30px;
  }
  #tz_append {
    a {
      background: #ff5400;
      border-color: #ff5400 !important;
      color: #ffffff;
      font-size: 12px;
      text-decoration: none;
      text-transform: uppercase;
      font-family: 'Montserrat', sans-serif, Arial, sans-serif;
      padding: 10px 20px !important;
      font-weight: 500;
    }
  }
  #tz_options {
    a {
      padding: 7px 15px !important;
      .transition(all 0.3s ease 0.01s);
      &.selected,
      &:hover {
        background: #ff5400;
        border-color: #ff5400 !important;
        color: #ffffff;
      }
    }
  }
}

.TzItemPageInner {
  .TzAgencyInfo {
    background: #ffffff;
    padding: 0 20px 40px;
    .box-shadow(0 2px 3px 0 rgba(0, 0, 0, 0.1));
  }

  .TzAgencyInfo--left {
    margin: 0 -15px;
    .box-shadow(0 2px 3px 0 rgba(0, 0, 0, 0.1));
    background: #f4f5f8;
    padding: 25px;
    .TzArticleCategory,
    .TzModified,
    .TZPublished,
    .TzCreate,
    .TzVote,
    .TzCreatedby,
    .TzExtrafield-item,
    .TzHits {
      display: flex;
      padding: 10px 0;
    }
    .right {
      flex: 1;
      font-size: 12px;
      color: #565656;
      text-align: left;
      * {
        color: #565656;
        font-size: 12px;
        line-height: 2em;
      }
      a {
        &:hover {
          color: #565656;
          text-decoration: none;
        }
      }
    }
    .left {
      width: 30px;
    }
  }

  .TzAgencyInfo--right {
    padding: 0 15px 0 25px;
    @media (max-width: 991px) {
      padding: 0;
    }
    .TzTitle {
      font-size: 30px;
      font-family: 'Montserrat', sans-serif, Arial, sans-serif;
      font-weight: bold;
      color: #222222;
      padding-left: 30px;
      border-left: 6px solid #ff5400;
      margin: 40px 0 35px 0;
    }
    p {
      margin-bottom: 25px;
    }
  }

  .TzAgencyMedia {
    min-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: -100px -15px 10px;
    background: #fff;
    .box-shadow(0 2px 3px 0 rgba(0, 0, 0, 0.1));
  }

  .TzAgencyBlock {
    .box-shadow(0 2px 3px 0 rgba(0, 0, 0, 0.1));
    background: #ffffff;
    padding: 30px 0;
    margin: 40px 0 0;
    @media (max-width: 991px) {
      margin-bottom: 40px;
    }
  }

  .TzAgencyUser {
    display: flex;
    align-items: center;
    padding: 0 15px;
    @media(max-width: 767px){
      flex-wrap: wrap;
      justify-content: center;
      .media-body{
        width: 100%;
      }
    }
    .AuthorAvatar {
      border: 3px solid #ff5400;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      padding: 6px !important;
      margin-right: 30px !important;
      min-width: 118px;
      height: 118px;
      @media(max-width: 767px){
        margin-right: 0 !important;
        margin-bottom: 30px;
      }
      .author-icon {
        top: 0;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        overflow: hidden;
        font-size: 100px;
        line-height: 100px
      }
      img {
        max-width: none;
        border-radius: 50%;
        height: 100%;
      }
    }

    .info {
      font-size: 12px;
      a {
        font-size: 12px;
        color: #ff5400;
      }
    }
    .AuthorDescription {
      font-size: 12px;
    }
    .media-heading {
      margin-bottom: 15px;
      a {
        font-size: 12px;
        font-family: 'Montserrat', sans-serif, Arial, sans-serif;
        font-weight: 500;
        color: #ff5400;
        display: block;
        line-height: 1em;
      }
    }
    .AuthorSocial {
      .TzSocialLink {
        font-size: 12px;
        color: inherit;
        &:hover {
          color: #ff5400;
          text-decoration: none;
        }
      }
    }
    .AuthorDescription {
      margin-bottom: 10px;
    }
  }
  .TzArticleTag {
    a {
      &:hover {
        background: #ff5400 !important;
      }
    }
  }
  .TzExtrafield-item {
    .tz_extrafield-label {
      width: 100%;
    }
  }
}

.TzRelatedTitle {
  margin: 0 !important;
  padding-left: 20px;
  border-left: 6px solid #ff5400;
  font-family: 'Montserrat', sans-serif, Arial, sans-serif !important;
  font-weight: bold !important;
}

.TzRelatedContent {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  align-content: center;

  .TzItem {
    width: 25%;
    position: relative;
    margin-bottom: 20px;
    text-align: center;
    @media (max-width: 767px) {
      width: 50%;
    }
  }
  .TzImage {
    display: flex;
    min-height: 220px;
    align-items: center;
    text-align: center;
    .transition(all 0.3s ease 0s);
    padding: 0 15px;
    a {
      margin: auto;
    }
  }
  .TzTitle {
    color: #565656;
    font-family: 'Montserrat', sans-serif, Arial, sans-serif;
    text-transform: uppercase;
    font-weight: bold;
    display: block;
    padding: 0 15px;
    &:hover {
      text-decoration: none;
      color: #ff5400;
    }
  }
}