.profile-edit {
  .control-label {
    label {
      display: inline-block !important;
    }
  }
  textarea,
  input[type='password'],
  input[type='tel'],
  input[type='url'],
  input[type='email'],
  input[type='text'] {
    width: 100%;
    line-height: 35px;
    border: 1px solid rgba(0, 0, 0, 0.07);
    padding: 0 15px !important;
    font-size: 13px;
  }
  .radio {
    label {
      font-size: 13px;
    }
  }
  input[type='radio'] {
    margin: 4px 0 0;

  }
  .btn.modal {
    position: relative;
  }
  #member-profile fieldset {
    padding: 10px 15px;
    margin-bottom: 20px;
    width: 100%;
    &.radio {
      margin: 0;
    }
    legend {
      font-size: 16px;
    }
  }
  .controls {
    .input-prepend {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }
    .media-preview {
      order: 1;
    }
    .btn.modal {
      order: 2;
      margin: 0 5px;
      background: #ff5400 !important;
      border-color: #ff5400 !important;
      margin-right: 10px;
      color: #ffffff !important;
      padding: 3px 10px !important;
    }
    .hasTooltip {
      order: 3;
      background: #222222 !important;
      border-color: #222222 !important;
      color: #ffffff !important;
      padding: 3px 10px !important;
    }
  }
  .control-group {
    display: flex;
    align-items: center;
    margin: 0 -15px;
    .control-label {
      width: 25%;
      padding: 0 15px;
    }
    .controls {
      padding: 0 15px;
      width: 75%;
    }
    .field-calendar {
      button {
        position: absolute;
        right: 15px;
        top: 0;
        padding: 9px !important;
      }
    }
  }
}

.tip-wrap {
  background: white;
  padding: 10px;
  .box-shadow(rgba(0, 0, 0, 0.1) 0px 2px 3px 0px);
}

#jform_params_tz_portfolio_plus_user_social_link-content {
  .control-group {
    display: flex;
    align-items: center;
  }
  .control-label {
    flex: 0.2;
    text-align: left;
    margin: 0;
    padding: 0;
  }
  .controls {
    flex: 0.8;
  }
  .control-label,
  .controls {
    margin: 10px 0;
  }
  .btn {
    padding: 5px 15px;
    margin-top: 5px;
  }
  .tz_btn-add {
    background: #ff5400;
    border-color: #ff5400;
    margin-right: 10px;
    span {
      margin-right: 3px;
    }
  }
  .tz_btn-reset {
    background: #222222;
    border-color: #222222;
    color: #ffffff;
  }
}