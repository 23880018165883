.ui-slider-horizontal {
  max-width: 100%;
  left: 5px;
  background: #ededed;
  height: 6px;
  border-color: #ededed;
  .ui-slider-handle {
    width: 13px;
    height: 13px;
    border: 3px solid #ff5400;
    background: #ffffff;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    top: -5px;
  }
  .ui-slider-range {
    background: #ff5400;
  }
}

.hikashop_filter_cursor_range {
  color: inherit;
  font-weight: normal;
}

.hikashop_filter_main_div {
  form::after,
  &:after {
    clear: both;
    content: '';
    display: block;
  }
  br {
    //display: none;
  }
  .hikashop_filter_main {
    padding-top: 22px;
    .chzn-container-multi .chzn-choices input[type="text"],
    input[type="text"] {
      width: 100% !important;
      border: 1px solid #dfdfdf !important;
      line-height: 35px !important;
      font-size: 12px !important;
      padding: 0 10px !important;
      background: transparent !important;
      margin: 0;
      height: auto;
      font-family: inherit;
    }
  }
  .chzn-container-single,
  .chzn-container-multi {
    width: 100% !important;
  }
  .chzn-choices {
    padding: 0;
    border: none;
    background: transparent;
    li {
      width: 100% !important;
    }
  }

  .chzn-container-single .chzn-single {
    border-radius: 0;
    .box-shadow(none);
    background: transparent;
    border-color: #ccc;
    line-height: 35px;
    height: auto;
    div {
      width: 15px;
      right: 10px;
      b {
        background-position: 0 7px;
      }
    }
  }
  .chzn-container-active.chzn-with-drop .chzn-single div b {
    background-position: -15px 7px;
  }
  .hikashop_filter_checkbox {
    display: inline-block;
    padding-bottom: 3px;
  }
  label {
    font-weight: normal;
    font-family: inherit;
  }
  .hikashop_filter_title {
    display: inline-block;
    font-weight: normal;
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    text-transform: uppercase;
    padding-bottom: 3px;
    margin: 0;
    line-height: 25px;
  }
  input[type=checkbox], input[type=radio] {
    margin-right: 3px;
  }
  .hikashop_filter_list_style {
    padding-left: 20px;
    list-style: circle;
    li {
      list-style: inherit;
      &:before {
        content: none;
      }
    }
  }
  label,
  a {
    font-size: 12px;
    font-weight: normal;
  }
}

.hikashop_reset_button_inside a,
.hikashop_filter_button_inside a,
.hikashop_filter_button_outside a,
.hikashop_reset_button_outside a {
  display: block;
  float: none !important;
  text-transform: uppercase;
  color: #fff !important;
  background: #222222;
  text-align: center;
  padding: 0 30px;
  height: 45px;
  line-height: 45px;
  .transition(all 0.3s linear);
  border: 1px solid transparent;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  margin: 35px 0 0;
  &:hover {
    display: block;
    background: #ff5400;
  }
}

.hikashop_reset_button_inside a {
  margin-right: 0;
}

.hikashop_reset_button_outside a {
  margin-top: 15px;
}

.hikashop_product_quantity_input_div_regrouped {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  input {
    height: auto !important;
    width: auto;
    max-width: 40px;
    text-align: center;
    line-height: 32px;
    border: 1px solid #dfdfdf;
    margin: 0 !important;
    padding: 0 !important;
  }
  .hikashop_product_quantity_change_div_plus_regrouped {
    line-height: 14px;
    background: #ffffff;
    margin-left: -1px;
    position: relative;
    border: 1px solid #dfdfdf;
    //margin-top: -1px;
    .transition(all 0.3s linear 0s);
    a {
      color: #222222 !important;
      margin: 0;
      padding: 5px !important;
      font-size: 12px;
    }
    &:hover {
      background: #ff5400;
      border-color: #ff5400;
      a {
        color: #ffffff;
      }
    }
  }
}

.hikashop_product_quantity_add_to_cart_div_regrouped {
  display: block;
}

.hikashop_products_listing {
  .hikashop_subcontainer {
    padding: 0;
    margin: 20px 0;
    background: transparent !important;
    > * {
      position: relative;
      overflow: hidden;
      background: #fff;
      -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
      padding-bottom: 3px;
    }
    &:hover {
      .tz__block__quantity {
        top: 0;
      }
      .tz__block__add_to_cart_listing {
        opacity: 1;
        visibility: visible;
        .hikabtn {
          opacity: 1;
          visibility: visible;
        }
        .tz__add_to_wishlist {
          right: 0;
        }
        .tz__add_to_cart {
          left: 0;
        }
      }
      .hikashop_listing_img_title .hikashop_product_image {
        &:after {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
  .hikashop_product_image {
    width: 100%;
    padding: 0;
    margin: 0;
    position: relative;
    img {
      width: 100%;
    }
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.8);
      .transition(all 0.3s linear);
      opacity: 0;
      visibility: hidden;
    }
  }
  .tz__block__quantity {
    position: relative;
    top: -10px;
    .transition(all 0.3s linear 0s);
  }
  .tz__block__add_to_cart_listing {
    position: absolute;
    text-align: center;
    left: 0;
    top: 50%;
    width: 100%;
    .translate(0, -50%);
    overflow: hidden;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    .transition(all 0.3s ease-in-out 0s);
    .hikabtn {
      padding: 0;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      width: 35px;
      height: 35px;
      line-height: 35px;
      background: #ff5400;
      border: none;
      margin: 0 5px;
      position: relative;
      opacity: 1;
      visibility: visible;
      display: inline-block !important;
      .transition(all 0.3s ease-in-out 0s);
      > span {
        display: none;
      }
    }
  }
  .tz__add_to_cart {
    left: -20%;
  }
  .tz__add_to_wishlist {
    right: -20%;
  }
  .hikashop_product_stock_count {
    font-size: 12px;
    display: none;
  }
  .hikashop_product_name {
    padding: 0 15px;
    margin: 23px 0 20px;
    border-left: 3px solid #ff5400;
    line-height: 14px;
    a {
      color: #222222;
      line-height: 14px;
      display: block;
    }
    .hikashop_product_code_list {
      display: block;
      word-break: break-all;
      a {
        font-weight: normal;
        font-size: 11px;
        display: block;
        //padding-left: 5px;
      }
    }
  }
  .tz__block--bottom {
    margin: 3px 3px 0;
    padding: 10px 15px;
    font-size: 15px;
    color: #fff;
    background: #ff5400;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    position: relative;
    display: flex;
    * {
      color: #ffffff;
    }
    .hikashop_product_price_full {
      font-size: 15px;
      font-weight: 700;
      color: #fff;
      margin: 0;
      height: auto !important;
      padding: 0 !important;
      flex: 1;
    }
    .hikashop_product_price,
    .hikashop_product_price_with_discount {
      font-weight: 700;
      font-style: normal;
      font-family: 'Montserrat', sans-serif;
    }
    .hikashop_product_price_before_discount {
      opacity: 0.8;
      margin-right: 15px;
    }
  }
  .hikashop_product_description_main_mini {
    display: none;
  }
  .hikabtn-compare {
    margin-left: 20px;
    margin-bottom: 15px;
    background: #222222;
    color: #ffffff;
    border-color: #222222;
    .transition(all 0.3s ease 0s);
    span {
      font-size: 12px;
    }
    i {
      margin-right: 5px;
    }
    &:hover {
      background: #ff5400;
      border-color: #ff5400;
    }
  }
  .tz__compare {
    margin-left: 15px;
    margin-bottom: 15px;
    input {
      top: 1px;
      position: relative;
      margin-right: 2px;
    }
  }
}

.hikashop_products_listing_main {
  .hikashop_filter_main_div {
    background: #fff none repeat scroll 0 0;
    .box-shadow(0 2px 2px 0 rgba(0, 0, 0, 0.1));
    color: #222222;
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 15px;
    padding: 22px;
    .hikashop_filter_main {
      padding-left: 10px;
      padding-right: 10px;
    }
    > form {
      display: block;
      margin: 0 -10px !important;
    }
  }
}

.pagination_block {
  background: #fff none repeat scroll 0 0;
  .box-shadow(0 2px 2px 0 rgba(0, 0, 0, 0.1));
  color: #222222;
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 15px;
  padding: 22px;
  display: flex;
  align-items: center;
  form {
    flex: 1;
  }
  .tzview-style {
    display: flex;
    flex: 0.3;
    align-items: center;
    justify-content: flex-end;
    font-size: 14px;
    label {
      font-size: 13px;
      font-weight: 700;
      line-height: 16px;
      color: #222222;
      margin-right: 7px;
    }
    .switchToGrid, .switchToList {
      min-width: 20px;
      text-align: center;
      cursor: pointer;
      &:hover {
        color: #ff5400;
      }
      i {
        display: block !important;
      }
    }
  }
  .hikashop_products_pagination {
    font-size: 13px;
    font-weight: 700;
    color: #222;
    line-height: 20px;
    margin: 0;
    padding: 0;
    text-align: left;
    span {
      color: #222;
    }
  }
}

.hikashop_product_left_part {
  @media (max-width: 991px) {
    margin-bottom: 30px;
  }
}

.hikashop_module {
  .pagination_block {
    display: none;
  }
}

.hikashop_products_pagination_bottom {
  padding-top: 25px !important;
  .list-footer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    @media (max-width: 480px) {
      display: block;
    }
  }
  .limit {
    flex: 0.3;
    text-align: left;
    margin: 0 !important;
    padding: 7px 5px;
    border: 1px solid #dfdfdf;
    background: #fff;
    line-height: 24px;
    @media (max-width: 767px) {
      flex: inherit;
      width: 100%;
      margin-bottom: 15px !important;
    }
    .chzn-container-single {
      float: right;
      .chzn-single {
        border: none;
        height: 24px;
        .box-shadow(none);
        background: transparent;
      }
    }
    .chzn-drop {
      .box-shadow(none);
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0;
      border: 1px solid #dfdfdf;
      padding-top: 5px;
    }
  }
  .pagination {
    flex: 1;
    text-align: right;
    padding: 0;
    margin: 0;
    height: 40px;
    li {
      max-height: 40px;
      min-height: 40px;
      line-height: 40px;
      display: inline-block;
      margin-left: 10px;
      cursor: pointer;
      @media (max-width: 480px) {
        margin-left: 5px;
      }
      a, a.pagenav {
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
        float: left;
        max-height: 40px;
        min-height: 40px;
        min-width: 40px;
        padding: 0 !important;
        display: inline-block;
        line-height: 40px !important;
        color: inherit;
        border-color: #ffffff;
        .box-shadow(0 2px 3px rgba(0, 0, 0, 0.1));
        .transition(all 0.3s linear 0s);
        font-weight: normal;
      }
      &.active, &:hover {
        a {
          background: #ff5400;
          border-color: #ff5400;
          color: #ffffff !important;
        }
      }
    }
  }
}

.tz__block__add_to_cart_listing--list {
  display: none;
}

.hikashop_products_listing.list {
  .hikashop_product {
    width: 100%;
  }
  .hikashop_subcontainer {
    min-height: 0 !important;
    &:hover {
      .hikashop_product_image:after {
        opacity: 0;
        visibility: hidden;
      }
    }
    > * {
      padding: 0;
    }
  }
  .hikashop_product_image {
    width: 260px;
  }
  .hikashop_listing_img_title {
    display: flex;
  }
  .tz_block--content {
    flex: 1;
    padding: 6px 25px 0 30px;
  }
  .tz__block__add_to_cart_listing--list {
    display: block;
    .hikashop_product_stock_count {
      float: right;
    }
    .hikabtn {
      background: transparent;
      border: 1px solid #ff5400;
      color: #ff5400;
      font-size: 12px;
      line-height: 1.5em;
      -webkit-border-radius: 2px;
      -moz-border-radius: 2px;
      border-radius: 2px;
      padding: 5px 12px;
      margin-right: 5px;
      .transition(all 0.3s ease-in-out);
      i {
        margin-right: 5px;
      }
      &:hover {
        background: #ff5400;
        color: #ffffff;
      }
    }
  }
  .tz__block__add_to_cart_listing {
    display: none;
  }
  .tz__block--bottom {
    background: transparent;
    padding-left: 0;
    margin: 0;
    a {
      display: none;
    }
    * {
      color: #ff5400;
    }
  }
  .hikashop_product_vote {
    padding-left: 0;
    margin-bottom: 0;
  }
  .hikashop_product_name {
    padding-left: 30px;
    margin-left: -30px;
    margin-bottom: 10px;
  }
  .hikashop_product_description_main_mini {
    display: block;
    margin: 10px 0;
    p {
      font-size: 12px;
      line-height: 20px;
    }
  }
}

.hikashop_module {
  .hikashop_subcategories {
    h3 {
      &.title a {
        padding: 5px 20px !important;
        display: block;
        line-height: 1.5em;
        &:hover {
          color: #ff5400;
        }
      }
      &.pane-toggler-down a {
        color: #ff5400;
      }
    }
    .hikashop_category_list {
      display: block;
      padding: 10px 0;
    }
    .hikashop_category_list_item {
      display: block;
      padding-left: 15px;
      padding-right: 15px;
      a {
        font-size: 12px;
        color: #222222;
        &:hover {
          color: inherit;
        }
      }
    }
  }
}

/* Vote Product */

.hk-rating {
  position: relative;
  display: block;
  line-height: 1em;
  .hk-rate-star {
    background: none !important;
    font-size: 14px;
    height: auto;
    width: auto;
    line-height: 1em;
  }
}

.hikashop_products_listing {
  .hikashop_product_vote {
    padding-left: 15px;
    margin-bottom: 15px;
    line-height: 1em;
  }
  .hikashop_vote_stars {
    padding: 0;
    width: auto;
    display: inline-block;
  }
  .hk-rating {
    .hk-rate-star {
      font-size: 12px;
      margin-right: 5px;
      &:first-child {
        margin-left: 3px;
      }
      &:last-child {
        margin: 0;
      }
    }
  }
}

.state-empty:before,
.state-full:before,
div.ui-rating .ui-rating-full,
div.ui-rating .icon-star:before,
div.ui-rating a.ui-rating-empty:before {
  content: "\f005";
}

.state-full,
.state-empty,
div.ui-rating .ui-rating-full,
div.ui-rating a.icon-star,
div.ui-rating a.ui-rating-empty {
  font-family: FontAwesome;
  color: #c0c0c0 !important;
}

.state-full:before,
.state-full.state-hover:before,
.state-empty.state-hover:before,
div.ui-rating > .rating-item:hover span:before,
div.ui-rating > .rating-item:hover ~ a span:before,
div.ui-rating > .voted:before,
div.ui-rating > .rating-item:hover:before,
div.ui-rating > .rating-item:hover ~ .rating-item:before,
div.ui-rating a.ui-rating-hover:before,
div.ui-rating a.ui-rating-full:before {
  color: #ffd800 !important;
  content: "\f005";
}

.hikashop_vote_form {
  width: auto;
  margin: 0;
  .hikashop_vote_stars,
  .hikashop_form_comment {
    font-family: 'Montserrat', sans-serif;
  }
  .hikashop_comment_form {
    width: auto;
  }
  input, textarea.hikashop_comment_textarea {
    line-height: 35px;
    width: 100%;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    padding: 0 15px;
    margin-bottom: 15px;
    font-size: 12px;
    border-color: #dfdfdf;
  }
  .btn {
    background: #ff5400;
    border-color: #ff5400;
    color: #ffffff;
  }
  .hk-rating {
    display: inline-block;
  }
}

.hikashop_listing_comment {
  .tz_top {
    display: flex;
    align-items: center;
    .tz_label {
      font-family: 'Montserrat', sans-serif;
      font-weight: 700;
      flex: 1;
    }
    .chzn-container-single {
      width: auto !important;
      font-size: 12px;
      .chzn-single {
        font-size: 12px;
        font-weight: 700;
      }
    }
    .tz_left, .tz_right {
      flex: 0.5;
    }
    .tz_right {
      display: flex;
      align-items: center;
      text-align: right;
      justify-content: flex-end;
    }
  }
  .hika_comment_listing_stars {
    width: auto;
    flex: 1;
    padding-right: 15px;
  }
  .tz__useful, .tz__useless {
    width: 25px;
    padding-left: 5px;
  }
  .hika_comment_listing_useful_p,
  .hika_comment_listing_useless_p {
    width: auto;
    height: auto;
    font-size: 12px;
    font-weight: normal;
    padding: 0;
    border: none;
    color: inherit;
  }
  .hika_comment_listing_useless,
  .hika_comment_listing_useful {
    width: auto !important;
  }
  .hika_comment_listing_content {
    padding: 15px 0 0 0;
  }
}

.hika_comment_listing {
  border-color: #dfdfdf;
  padding: 15px;
}

.hika_comment_listing_name, .hika_comment_listing_date {
  display: inline-block;
  font-size: 14px;
  font-weight: 300;
  color: inherit;
}

.hika_comment_listing_date {
  font-size: 12px;
  &:before {
    content: '-';
  }
}

/* Cart */
div.hikashop_cart_dropdown_content {
  border-color: #dfdfdf;
  padding: 30px;
  border-top: 3px solid #ff5400;
  .box-shadow(0 2px 5px rgba(0, 0, 0, 0.1));
  @media (max-width: 767px) {
    left: -20px !important;
    right: -20px !important;
  }
}

.hikashop_small_cart_checkout_link {
  position: relative;
  display: block;
  &.style_2 {
    font-size: inherit;
    color: #222222;
    .hikashop_small_cart_total_title {
      background: #ff5400;
      width: 17px;
      height: 17px;
      line-height: 17px;
      text-align: center;
      word-break: break-all;
      font-size: 10px;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      color: #ffffff;
      display: inline-block;
      position: relative;
      top: -6px;
      left: -3px;
    }
  }
}

.tz__mod__cart {
  display: flex;
  align-items: flex-start;
  padding: 0 0 30px 0;
  margin-bottom: 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
  &:last-child {
    margin-bottom: 20px;
  }
  .hikashop_cart_module_product_image {
    width: 81px;
    height: 81px;
    img {
      width: 100%;
    }
  }
  .tz__cart__info {
    padding-left: 20px;
    flex: 1;
    padding-top: 10px;
  }
  .hikashop_cart_module_product_delete_value {
    width: 20px;
    text-align: right;
    padding-top: 8px;
    a {
      color: #222222;
      font-size: 14px;
    }
  }
  .hikashop_cart_module_product_name_value {
    margin-bottom: 15px;
    a {
      display: block;
      color: #222222;
      font-size: 14px;
      font-weight: 300;
      font-family: 'Muli', sans-serif;
    }
  }
  .hikashop_cart_module_product_quantity_value {
    display: inline-block;
    input {
      margin: 0 !important;
      border: 1px solid rgba(0, 0, 0, 0.07);
      float: none;
    }
  }
  .hikashop_product_discount,
  span.hikashop_product_price, .hikashop_checkout_cart_additional, .hikashop_checkout_cart_payment, .hikashop_checkout_cart_coupon, .hikashop_checkout_cart_additional {
    font-family: 'Montserrat', sans-serif, Arial, sans-serif;
    font-style: normal;
    color: #ff5400;
    font-weight: normal;
  }
  .hikashop_product_price {
    margin-left: 5px;
  }
  .hikashop_product_discount {
    opacity: 0.8;
    float: right;
    position: relative;
    top: -6px;
    font-size: 11px;
    left: 1px;
  }
  .hikashop_cart_module_product_price_value {
    display: inline-block;
  }

}

.hikashop_cart {
  .tz__block--bottom {
    display: flex;
    align-items: center;
    .hikabtn {
      flex: 0.5;
      background: #222222;
      border-color: #222222;
      color: #ffffff;
      font-weight: 300;
      &:hover {
        background: #ff5400;
        border-color: #ff5400;
      }
    }
    .hikashop_cart_module_product_total_value {
      flex: 1;
      text-align: right;
      color: #151515;
      font-weight: 300;
      font-style: normal;
      .hikashop_product_price_full {
        display: inline-block;
        color: #ff5400;
        * {
          color: #ff5400;
          font-family: 'Muli', sans-serif, Arial, sans-serif;
          font-style: normal;
          font-weight: 300;
        }
      }
    }
  }
  .tz__block__shipping,
  .tz__block__coupon {
    font-size: 13px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.07);
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
}

#hikashop_cart_product_listing {
  margin-top: 30px;
  th {
    font-weight: 700;
    color: #000000;
    text-transform: uppercase;
    font-size: 14px;
    border-bottom: 1px solid #468ae7;
    padding: 0 10px 15px;
    &:first-child {
      //padding-left: 50px;
    }
  }
  tr {
    background: transparent;
    &.margin {
      display: none;
    }
  }
  td {
    padding: 30px 10px;
    background: transparent;
    vertical-align: middle;
    td {
      padding: 0;
    }
    table {
      display: inline-block;
    }
  }
  .hikashop_cart_status_title {
    text-align: center;
  }
  .hikashop_cart_price_title {
    text-align: right;
  }
  .hikashop_cart_quantity_title {
    text-align: center;
  }
  .hikashop_product_price_full {
    .hikashop_product_price {
      font-weight: 700;
      color: #222222;
      font-style: normal;
      font-family: 'Muli', sans-serif, Arial, sans-serif;
    }
    .hikashop_product_price_before_discount {
      font-size: 11px;
    }
  }
  .hika_show_cart_total_price {
    font-weight: 700;
    color: #222222;
    font-style: normal;
    font-family: 'Muli', sans-serif, Arial, sans-serif;
  }

  .hikashop_product_quantity_field {
    float: none;
    background: #ebebeb;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    .box-shadow(none);
    border: 1px solid #ebebeb;
  }
  .flex {
    display: inline-block;
    .hikashop_product_quantity_input_div_regrouped {
      margin: 0;
    }
    .hikashop_no_print {
      img {
        vertical-align: text-bottom;
      }
    }
  }
  .hikashop_cart_product_name {
    display: inline-block;
    a {
      color: #222222;
      font-size: 14px;
      font-weight: 700;
    }
    .hikashop_cart_product_code {
      display: block;
      font-size: 12px;
    }
  }
  .hikashop_cart_product_image {
    display: inline-block;
    margin-right: 35px;
  }
}

.hika_options {
  .inputbox {
    line-height: 33px;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
  dt {
    top: 8px;
    position: relative;
  }
}

/* Product Detail */
#hikashop_small_image_div {
  padding: 0;
}

.lightSlider {
  img.hikashop_child_image {
    height: auto;
    margin: 0;
    width: 100%;
  }
}

h1#hikashop_product_name_main {
  font-size: 36px;
  line-height: 27px;
  color: #222;
  border-left: 6px solid #ff5400;
  padding-left: 15px;
  margin-bottom: 15px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  padding-bottom: 0;
  border-bottom: none;
}

.hikashop_product_page {
  .hikashop_product_vote_mini {
    padding-left: 20px;
    .hikashop_vote_stars {
      padding: 0;
    }
    .hk-rating {
      display: inline-block;
    }
    .hk-rate-star {
      margin: 0 4px;
      &:first-child {
        margin-left: 0;
      }
    }
  }
  .hikashop_product_top_part {
    margin-bottom: 40px;
    @media (max-width: 767px) {
      margin-bottom: 20px;
    }
  }
  #hikashop_product_price_main .hikashop_product_price_full {
    background: #ff5400 none repeat scroll 0 0;
    border: 3px solid #fff;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 10px;
    margin-top: 0;
    padding: 15px 20px 0 !important;
    text-align: left;
    text-transform: uppercase;
    display: block;
    line-height: 40px;
    @media (max-width: 767px) {
      margin: 0;
      height: auto !important;
      padding: 5px 20px 0 !important;
      line-height: 35px;
    }
    .hikashop_product_price {
      font-size: 36px;
      font-family: "Montserrat", sans-serif;
      color: #ffffff;
      font-style: normal;
      letter-spacing: 3px;
      font-weight: normal;
      @media (max-width: 767px) {
        font-size: 24px;
      }
    }
    .hikashop_product_price_before_discount {
      font-size: 12px;
      text-decoration: line-through;
      font-family: "Montserrat", sans-serif;
      color: #ffffff;
      margin-right: 15px;
    }
  }
  #hikashop_product_description_main_mini {
    display: block;
    p {
      margin: 0;
    }
    .title {
      position: relative;
      margin-bottom: 10px;
      &:before {
        background: #ff5400 none repeat scroll 0 0;
        content: "";
        display: block;
        height: 12px;
        left: -20px;
        position: absolute;
        top: 9px;
        width: 3px;
      }
      span {
        display: inline-block;
        padding-right: 20px;
        font-size: 16px;
        color: #363636;
        position: relative;
        &:after {
          background: #ff5400 none repeat scroll 0 0;
          content: "";
          display: block;
          height: 1px;
          left: 100%;
          margin-top: 4px;
          position: absolute;
          top: 50%;
          width: 45px;
        }
        &:before {
          background: #ff5400 none repeat scroll 0 0;
          content: "";
          display: block;
          height: 1px;
          left: 100%;
          margin-top: -3px;
          position: absolute;
          top: 50%;
          width: 30px;
        }
      }
    }
  }
  .tz__product--info {
    -webkit-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
    padding: 15px 20px;
    margin: 0 0 12px 0;
    background: #fff;
    @media (max-width: 991px) {
      margin-bottom: 30px;
    }
  }
  #hikashop_social {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    > span {
      float: none;
      max-width: 70px;
      overflow: hidden;
    }
    iframe {
      height: 20px;
    }
  }
  .hikashop_product_tags,
  .hikashop_product_code_main {
    display: flex;
    align-items: flex-start;
    word-break: break-all;
    font-size: 13px;
    font-weight: 300;
    margin-bottom: 15px;
    line-height: 1.5em;
    span:first-child {
      min-width: 60px;
      font-family: "Montserrat", sans-serif;
      font-size: 14px;
      font-weight: 700;
      float: none;
      color: #565656;
      padding-right: 10px;
    }
    span, a {
      color: inherit;
    }
  }
  .hikashop_product_tags {
    .list-inline > li {
      padding: 0;
      a {
        background: transparent;
        color: inherit;
        white-space: normal;
        padding: 0;
        margin: 0;
        font-size: 13px;
        font-weight: normal;
        &:after {
          content: ',';
        }
      }
      &:last-child a:after {
        content: none;
      }
    }
  }
  .hikashop_product_quantity_main,
  #hikashop_product_characteristics,
  .hikashop_product_description_main_mini {
    padding: 0 0 25px 0;
    margin-bottom: 30px;
    border-width: 0 0 1px 0;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.07);
  }
  .hikabtn {
    i {
      display: none;
    }
  }
  .hikashop_product_quantity_main {
    .tz__action {
      display: flex;
      align-items: flex-start;

      .block__buttons {
        //padding-left: 10px;
        a {
          margin-right: 0;
          margin-bottom: 5px;
        }
      }
    }
    .hikashop_product_stock_count {
      margin-bottom: 25px;
      padding-bottom: 30px;
      border-bottom: 1px dashed rgba(0, 0, 0, 0.07);
      font-size: 13px;
      i {
        margin-right: 5px;
        font-size: 20px;
        color: #ff5400;
      }
    }
    .hikashop_product_quantity_input_div_regrouped {
      margin-bottom: 0;
      input {
        height: auto;
      }
    }
    .hikabtn {
      background: #ff5400;
      color: #ffffff;
      border-color: #ff5400;
      font-family: 'Montserrat', sans-serif;
      font-size: 12px;
      margin-left: 10px;
      line-height: 20px;
    }
  }
  .hikashop_product_contact_main {
    a {
      display: flex;
      align-items: center;
      background: #565656;
      color: #ffffff !important;
      padding: 27px 30px;
      .box-shadow(0 2px 3px 0 rgba(0, 0, 0, 0.1));
      border: none;
      margin: 0;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0;
      text-align: left;
      i {
        font-size: 36px;
        padding-right: 30px;
      }
    }
  }
  .lSGallery li img,
  .lSSlideWrapper {
    .box-shadow(0 2px 3px 0 rgba(0, 0, 0, 0.1));
  }
  .lSGallery li {
    padding-bottom: 5px;
  }
  .tz__block__pagination {
    display: flex;
    align-items: center;
    padding: 20px 0;
    a {
      flex: 0.5;
      i {
        margin-right: 5px;
        font-size: 18px;
      }
      &:last-child {
        text-align: right;
        i {
          margin-left: 5px;
          margin-right: 0;
        }
      }
    }
  }
}

.hikashop_tabs_ul {
  display: block;
  margin: 0 0 0 4px;
  li {
    display: inline-block;
    font-family: 'Montserrat', sans-serif;
    //color: inherit;
    border-width: 0;
    border-color: transparent;
    border-style: solid;
    background: #565656;
    color: #ffffff;
    padding: 15px 20px;
    font-weight: normal;
    position: relative;
    .transition(all 0.3s ease 0.2s);
    margin-right: 2px;
    &:after {
      content: '';
      height: 3px;
      width: 0;
      top: -3px;
      background: #ff5400;
      position: absolute;
      left: 0;
      .transition(all 0.3s ease 0.2s);
    }
    &.hikashop_tabs_li_selected {
      color: #565656 !important;
      &:after {
        width: 100%;
      }
    }
  }
}

.hikashop_tabs_content {
  border: none;
  .box-shadow(0 2px 3px 0 rgba(0, 0, 0, 0.1));
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  padding: 30px 25px;
  #hikashop_product_description_main {
    padding: 0;
  }
}

/* Page Compare */
.hikashop_header_buttons {
  float: none;
  text-align: right;
  margin-bottom: 20px;
  &.toolbar a {
    display: inline-block;
    float: none;
    padding: 7px 15px;
    background: #ff5400;
    color: #ffffff;
    .transition(all 0.3s ease 0s);
    span {
      display: inline-block;
      width: auto;
      height: auto;
    }
    &:hover {
      background: #222222;
    }
  }
  .hkdropdown-menu {
    text-align: center;
    border: none;
    .box-shadow(0 2px 3px 0 rgba(0, 0, 0, 0.2));
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    > li {
      > a:focus, > a:hover {
        color: #ffffff;
      }
    }
  }
}

.hikashop_compare_table {
  //display: flex;
  //flex-wrap: wrap;
  //width: auto;
  //margin: 0 -15px;
  .hikashop_compare_tr_name {
    //width: 50%;
    //padding: 15px;
  }
  .hikashop_compare_title_prod_column {
    position: relative;
    margin: 25px 0 0 21px;
    a {
      display: block;
      &:hover, &:focus {
        color: inherit;
      }
    }
    &:before {
      background: #ff5400 none repeat scroll 0 0;
      content: "";
      display: block;
      height: 14px;
      left: -20px;
      position: absolute;
      top: 4px;
      width: 3px;
    }
    span {
      display: inline-block;
      padding-right: 20px;
      font-size: 16px;
      color: #363636;
      position: relative;
      &:hover, &:focus {
        color: #ff5400;
      }
      &:after {
        background: #ff5400 none repeat scroll 0 0;
        content: "";
        display: block;
        height: 1px;
        left: 100%;
        margin-top: 4px;
        position: absolute;
        top: 50%;
        width: 45px;
      }
      &:before {
        background: #ff5400 none repeat scroll 0 0;
        content: "";
        display: block;
        height: 1px;
        left: 100%;
        margin-top: -3px;
        position: absolute;
        top: 50%;
        width: 30px;
      }
    }
  }
  .hikashop_compare_title_first_column {
    background: #ffffff;
    overflow: hidden;
    word-wrap: break-word;
    //padding: 15px;
    .box-shadow(0 2px 3px 0 rgba(0, 0, 0, 0.1));

  }
  .tz_top {
    display: flex;
  }
  .hikashop_compare_details_prod_column {
    padding: 0;
    border: none;
    text-align: left;
    word-wrap: break-word;
    overflow: hidden;
  }
  .hikashop_product_name_main {
    font-size: 16px;
    display: block;
    text-align: left;
  }
  .hikashop_product_code_main {
    display: block;
    font-size: 12px;
    font-weight: 300;
    font-family: 'Muli', sans-serif, Arial, sans-serif;
    line-height: 1.2em;
  }

  .hikashop_product_price_main {
    margin: 3px;
    background: #ff5400;
    padding: 10px 20px;
    .hikashop_product_price_full {
      text-align: left;
      line-height: 22px;
    }
    .hikashop_product_price {
      color: #ffffff;
      font-family: 'Montserrat', sans-serif, Arial, sans-serif;
      font-style: normal;
      font-size: 15px;
    }
    .hikashop_product_price_before_discount {
      color: #ffffff;
      font-family: 'Montserrat', sans-serif, Arial, sans-serif;
      font-style: normal;
      opacity: 0.8;
      margin-right: 13px;
      padding-right: 13px;
      border-right: 1px solid rgba(255, 255, 255, 0.5);
      display: inline-block;
      line-height: 1em;
      font-size: 14px;
    }
  }
  .hikashop_compare_cart_prod_info {
    padding: 20px;
    text-align: left;
    > span {
      //border-right: 1px solid rgba(0, 0, 0, 0.07);
      //padding-right: 5px;
      display: block;
      padding: 10px 0;
      color: #222222;
      font-weight: 700;
      font-family: 'Montserrat', sans-serif, Arial, sans-serif;
      span {
        display: inline-block;
        font-family: 'Muli', sans-serif, Arial, sans-serif;
        font-weight: normal;
        color: #868686;
        min-width: 70px;
      }
      label {
        font-weight: normal;
      }
      &:last-child {
        border: none;
        padding-right: 0;
      }
    }
  }
  .hikashop_compare_cart_prod_column {
    padding: 0 20px 20px;
    text-align: left;
    form {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      > * {
        margin: 5px 5px 5px 0;
      }
    }
    .hikashop_product_quantity_input_div_regrouped {
      margin: 0;
    }
    .hikabtn {
      background: #ff5400;
      border-color: #ff5400;
      margin: 5px 5px 5px 0;
    }
  }
  .tz__custom_field {
    display: flex;
    align-items: center;
    padding: 5px 0;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.07);
  }
  .hikashop_product_custom_name {
    flex: 0.3;
    text-align: left;
  }
  .hikashop_product_custom_value {
    flex: 1;
    text-align: left;
  }
  .tz__readmore {
    float: right;
    color: #ffffff;
    top: 1px;
    position: relative;
  }
}

.hikashop_compare_page {
  .owl-nav {
    > div {
      position: absolute;
      top: 50%;
      font-size: 60px;
      margin-top: -30px;
      line-height: 60px;
      //height: 60px;
      color: #cacaca;
      //.translate(0,-50%);
      .transition(all 0.3s ease 0.05s);
      &:hover {
        color: #ff5400;
      }
      &.owl-prev {
        left: -40px;
      }
      &.owl-next {
        right: -40px;
      }
    }
  }
}

/* Page Cpanel */
#hikashopcpanel {
  margin-top: 20px;
  .hk-row-fluid {
    display: flex;
    align-items: center;
  }
  div.icon {
    float: none;
    a {
      width: 100%;
      height: auto;
      padding: 10px 15px;
      background: #ff5400 !important;
      border-color: #ff5400 !important;
      color: #ffffff;
      span {
        display: inline-block;
        text-align: left;
        color: #ffffff;
      }
    }
  }
}

/* Page Affiliate*/
#hikashop_affiliate_main {

  fieldset {
    border: none;
    position: relative;
  }
  .hikashop_header_title {
    display: block;
    float: none;
    h1 {
      font-size: 36px;
      margin: 0;
      line-height: 1.2em;
    }
  }
  .hikashop_header_buttons {
    position: absolute;
    top: 5px;
    right: 0;
  }
  #adminForm {
    legend {
      font-size: 16px;
    }
    tr:first-child > td {
      border-top: none;
    }
    td {
      vertical-align: middle;
    }
  }

  input[type='text'] {
    width: 100%;
    line-height: 35px;
    border: 1px solid rgba(0, 0, 0, 0.07);
    padding: 0 15px !important;
    font-size: 13px;
  }
}

/* Page Contact*/
.hikashop_product_contact_page {
  textarea,
  input[type='password'],
  input[type='tel'],
  input[type='url'],
  input[type='email'],
  input[type='text'] {
    width: 100%;
    line-height: 35px;
    border: 1px solid rgba(0, 0, 0, 0.07);
    padding: 0 15px !important;
    font-size: 13px;
  }
  dl {
    dd, dt {
      margin-bottom: 15px;
    }
  }
  .toolbar .btn {
    background: #ff5400 !important;
    color: #ffffff !important;
    border-color: #ff5400 !important;
    i {
      margin-right: 5px;
    }
  }
}

/* Page Downloads */
#hikashop_order_listing,
#hikashop_download_listing {
  .hikashop_header_title {
    display: block;
    float: none;
    h1 {
      font-size: 36px;
      margin: 0;
      line-height: 1.2em;
    }
  }
  .hikashop_search_block {
    display: flex;
    margin-bottom: 15px;
    input {
      flex: 1;
      //margin-right: 15px;
    }
    input[type='text'] {
      width: 100%;
      line-height: 35px;
      border: 1px solid rgba(0, 0, 0, 0.07);
      padding: 0 15px !important;
      font-size: 13px;
    }
    .btn {
      margin-left: 10px;
    }
  }
  th {
    padding: 10px;
  }
  tfoot td {
    padding: 10px;
    font-size: 13px;
  }
  .pagination {
    display: block;
  }
}

/* Page Entry*/
#hikashop_entries_info {
  legend {
    margin-bottom: 10px;
  }
  table {
    width: 100%;
    td {
      width: 100%;
      &.key {
        width: 30%;
      }
      input {
        width: 100%;
        line-height: 35px;
        border: 1px solid #dfdfdf;
        padding: 0 15px;
      }
    }
  }
}

.hikashop_delete_entry_button,
#hikashop_new_entry {
  display: inline-block;
  a {
    background: #ff5400;
    border: 1px solid #ff5400;
    padding: 5px 15px;
    color: #ffffff !important;
    display: block;
    .transition(all 0.3s ease 0s);
    &:hover {
      background: #222222;
      border-color: #222222;
      color: #ffffff;
      text-decoration: none;
    }
  }
}

form[name='hikashop_entry_form'] {
  .hikashop_cart_button {
    background: #ff5400;
    border: 1px solid #ff5400;
    padding: 5px 15px;
    color: #ffffff !important;
    display: inline-block;
    line-height: 1.5em;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    .transition(all 0.3s ease 0s);
    &:hover {
      background: #222222;
      border-color: #222222;
      color: #ffffff;
    }
  }
}

/*----- Checkout -----*/
#hikashop_checkout_page {
  .hikashop_cart_button {
    background: #ff5400;
    color: #ffffff !important;
    padding: 5px 20px;
  }
  .TzTitle {
    text-align: center;
    font-weight: 700;
    color: #ff5400;
    margin: 45px 0;
  }
  .hikashop_cart_bar {
    background: none;
    display: flex;
    justify-content: center;
    width: 70%;
    margin: 0 auto 45px;
    padding: 0;
    flex-wrap: wrap;
    @media (max-width: 991px) {
      width: 100%;
    }
  }
  div.hikashop_cart_step {
    background: none !important;
    padding: 0 35px;
    position: relative;
    z-index: 1;
    @media (max-width: 767px) {
      min-width: 125px;
      padding: 0 20px;
      margin-bottom: 10px;
    }
    @media (max-width: 480px) {
      min-width: 100px;
      padding: 0;
    }
    &:after {
      content: '';
      background: rgba(43, 43, 43, 0.2);
      width: 100%;
      position: absolute;
      top: 50%;
      height: 1px;
      left: 50%;
      .translate(-50%, 0);
      margin-top: 11px;;
      z-index: -1;
    }
    &.hikashop_cart_step_current {
      background: none;
      .TzStep {
        background: #ff5400;
      }
    }
    span {
      display: block;
      font-size: 11px;
      font-weight: 700;
      text-transform: uppercase;
      color: #222222;
      left: auto;
      font-family: 'Muli', sans-serif, Arial, sans-serif;
      a {
        color: #222222;
        font-weight: 700;
        font-size: 11px;
      }
    }
    .TzStep {
      //margin-top: 15px;
      cursor: pointer;
      width: 30px;
      height: 30px;
      line-height: 30px;
      background: #222222;
      color: #ffffff;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      margin: 10px auto 0;
      position: relative;
      a {
        color: #ffffff;
      }
    }
  }
}

.hikashop_checkout_login {
  width: auto;
  max-width: 510px;
  .nav {
    margin-bottom: 75px;
    margin-left: 0;
    @media (max-width: 767px) {
      margin-bottom: 30px;
    }
    li {
      min-width: 170px;
      font-size: 16px;
      font-weight: normal;
      margin: 0 -2px;
      @media (max-width: 480px) {
        min-width: 130px;
      }
      &:first-child {
        -webkit-border-radius: 10px 0 0 10px;
        -moz-border-radius: 10px 0 0 10px;
        border-radius: 10px 0 0 10px;
        background: #808080;
        &:hover {
          background: #ff5400;
        }
      }
      &:last-child {
        -webkit-border-radius: 0 10px 10px 0;
        -moz-border-radius: 0 10px 10px 0;
        border-radius: 0 10px 10px 0;
        background: #222222;
        &:hover {
          background: #ff5400;
        }
      }
      a {
        color: #ffffff;
        background: transparent !important;
      }
    }
  }
}

#hikashop_checkout_login_left_part {
  fieldset {
    margin: auto;
    padding: 0 50px;
    @media (max-width: 767px) {
      padding: 0 15px;
    }
    > * {
      position: relative;
    }
  }
  #com-form-login-password,
  #com-form-login-username {
    label {
      display: none;
    }
    input {
      width: 100%;
      border: 1px solid rgba(0, 0, 0, 0.1);
      line-height: 33px;
      padding: 5px 20px;
      margin: 0 0 15px;
      color: #b1b1b1;
      &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: #b1b1b1;
      }
      &::-moz-placeholder { /* Firefox 19+ */
        color: #b1b1b1;
      }
      &:-ms-input-placeholder { /* IE 10+ */
        color: #b1b1b1;
      }
      &:-moz-placeholder { /* Firefox 18- */
        color: #b1b1b1;
      }
    }
  }
  .forget {
    position: absolute;
    top: 13px;
    right: 20px;
    font-size: 12px;
    font-weight: normal;
    color: #373737;
  }
  #com-form-login-remember {
    label {
      color: #373737;
    }
    input {
      //display: none;
      &:checked + label {
        color: #ff5400;
      }
    }
  }
  .hikashop_cart_button {
    text-transform: uppercase;
    color: #ffffff !important;
    min-width: 110px;
    text-align: center;
    line-height: 33px;
    padding: 5px 0;
    background: #ff5400;
    .box-shadow(none);
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    margin: 40px 0 0;
  }
}

.check-box-style1 {
  input {
    display: none;
    &:checked + label {
      color: #ff5400;
    }
  }
  label {
    font-weight: 300;
    cursor: pointer;
    &:before {
      content: '\f046';
      font-family: FontAwesome;
      margin-right: 8px;
    }
  }
}

#hikashop_checkout_login_right_part {
  .hikashop_registration_custom_field_line,
  #hikashop_registration_username_line,
  #hikashop_registration_name_line,
  .hikashop_registration_email_line,
  .hikashop_registration_email_confirm_line,
  #hikashop_registration_password2_line,
  #hikashop_registration_password_line {
    label {
      display: none;
    }
    input {
      width: 100%;
      border: 1px solid rgba(0, 0, 0, 0.1);
      line-height: 33px;
      padding: 5px 20px;
      margin: 0 0 15px;
      color: #b1b1b1;
      &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: #b1b1b1;
      }
      &::-moz-placeholder { /* Firefox 19+ */
        color: #b1b1b1;
      }
      &:-ms-input-placeholder { /* IE 10+ */
        color: #b1b1b1;
      }
      &:-moz-placeholder { /* Firefox 18- */
        color: #b1b1b1;
      }
    }
  }
  .input {
    padding: 0 50px;
    @media (max-width: 767px) {
      padding: 0 15px;
      select {
        width: 100% !important;
        margin-bottom: 15px;
        padding: 10px 15px;
        line-height: 35px;
      }
    }
  }
  .hikashop_cart_button {
    text-transform: uppercase;
    color: #ffffff !important;
    min-width: 110px;
    text-align: center;
    line-height: 33px;
    padding: 5px 0;
    background: #ff5400;
    .box-shadow(none);
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    margin: 40px 0 0;
  }
  .chzn-container-single {
    width: 100% !important;
    text-align: left;
    margin-bottom: 15px;
    .chzn-single {
      height: auto;
      line-height: 33px;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0;
      background: #ffffff;
      border: 1px solid rgba(0, 0, 0, 0.1);
      //color: rgba(0, 0, 0, 0.1);
      .box-shadow(none);
      padding: 5px 20px;
      color: #b1b1b1;
      div {
        width: 15px;
        right: 5px;
        b {
          background-position: 0 7px;
        }
      }
    }
    &.chzn-with-drop {
      .chzn-single div b {
        background-position: -18px 7px;
      }
    }
    .chzn-drop {
      .box-shadow(none);
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0;
      border-color: rgba(0, 0, 0, 0.1);
      padding: 0 10px;
    }
    .chzn-search input[type="text"] {
      line-height: 1.2em;
    }
  }
  .hikashop_field_required {
    position: absolute;
    right: 30px;
    top: 10px;
  }
  .hikashop_registration_custom_field_line {
    position: relative;
  }
}

.hikashop_checkout_address {
  width: 50%;
  margin: auto;
  .hikashop_checkout_address_right_part,
  .hikashop_checkout_address_left_part {
    width: auto;
    float: none;
    margin: 0 0 15px
  }
  .hikashop_field_required {

  }
}

#hikashop_checkout_shipping_address_zone,
#hikashop_checkout_billing_address_zone {
  td {
    position: relative;
  }
  .hikashop_field_required {
    position: absolute;
    top: 15px;
    right: -5px;
  }
  input, select {
    width: 100%;
    max-width: inherit;
    float: none;
    line-height: 24px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 5px 20px;
    height: auto;
  }
}

#hikashop_checkout_cart {
  br {
    display: none;
  }
  th {
    font-weight: 700;
    color: #000000;
    text-transform: uppercase;
    font-size: 14px;
    border-bottom: 1px solid #468ae7;
    padding: 0 10px 15px;
    &:first-child {
      padding-left: 50px;
    }
  }
  tr {
    background: transparent;
    &.margin {
      display: none;
    }
    @media (max-width: 991px) {
      border-top: none;
      border-color: #ddd;
      &:first-child {
        border-top: 1px solid #ddd;
      }
    }
  }
  td {
    padding: 30px 10px;
    background: transparent;
    vertical-align: middle;
    @media (max-width: 991px) {
      padding-left: 30%;
      text-align: left;
      padding-top: 20px;
      padding-bottom: 20px;
      width: 100%;
      border-top: none;
    }
  }
  img {
    max-width: 130px;
  }
  .hikashop_cart_coupon_title,
  .hikashop_cart_shipping_title,
  .hikashop_cart_payment_title,
  .hikashop_cart_total_title,
  .hikashop_cart_subtotal_title {
    font-size: 13px;
    font-weight: 700;
    color: #000000;
  }
  .hikashop_cart_coupon_value,
  .hikashop_cart_shipping_value,
  .hikashop_cart_payment_value,
  .hikashop_cart_total_value,
  .hikashop_cart_subtotal_value {
    text-align: right;
    * {
      font-size: 14px;
      font-weight: 700;
      font-style: normal;
      font-family: 'Muli', sans-serif, Arial, sans-serif;
    }
  }
  .hikashop_cart_subtotal_value * {
    color: #000000;
  }

  .hikashop_cart_coupon_value,
  .hikashop_cart_shipping_value,
  .hikashop_cart_payment_value {
    * {
      color: #8f8f8f;
    }
  }
  .hikashop_cart_total_value * {
    color: #ff5400;
  }
  .hikashop_cart_product_name {
    margin: 0;
    a {
      font-size: 14px;
      color: #595959;
      font-weight: 700;
      display: block;
    }
    .hikashop_product_code_checkout {
      display: block;
      opacity: 0.8;
      font-size: 11px;
    }
  }

  .hikashop_cart_product_price_title {
    text-align: left;
  }
  .hikashop_cart_product_total_value,
  .hikashop_cart_product_price_value {
    .hikashop_product_price_full {
      text-align: left;
      padding-left: 0;
      .hikashop_product_price {
        font-size: 14px;
        font-family: 'Muli', sans-serif, Arial, sans-serif;
        font-weight: 700;
        color: #2a2a2a;
        font-style: normal;
      }
      .hikashop_product_price_before_discount {
        font-size: 12px;
      }
    }
  }
  .hikashop_cart_product_total_value .hikashop_product_price_full {
    text-align: right;
  }
  .hikashop_cart_product_quantity_value {
    //text-align: center;
    input {
      float: none;
      background: #ebebeb;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      border-radius: 4px;
      .box-shadow(none);
      border: 1px solid #ebebeb;
    }
  }
}

.hikashop_payment_methods,
.hikashop_shipping_methods {
  margin: 15px 0;
}

.hikashop_products_listing {
  .hikashop_listing_img {
    display: flex;
    align-items: flex-start;
    .box-shadow(none);
    padding: 0;
    .hikashop_product_image {
      width: auto;
    }
    .content {
      padding-left: 10px;
      text-align: left;
    }
    .hikashop_product_vote {
      padding: 0;
    }
    .hikashop_product_name {
      border: none;
      padding: 0;
      margin: 0;
      a {
        font-size: 13px;
        font-family: 'Muli', sans-serif, Arial, sans-serif !important;
        text-transform: none;
        font-weight: 400;
      }
    }
    .tz__action {

    }
    .hikashop_product_quantity_div {
      display: none;
    }
    .hikashop_product_quantity_input_div_regrouped {
      justify-content: flex-start;
    }
    .hikashop_product_price_full {
      font-family: 'Montserrat', sans-serif, Arial, sans-serif;
      font-weight: 400;
      * {
        font-family: 'Montserrat', sans-serif, Arial, sans-serif;
        font-style: normal;
        color: #222222;
      }
      .hikashop_product_price_with_discount {
        margin-left: 5px;
      }
    }
    .hk-rating .hk-rate-star:first-child {
      margin-left: 0;
    }
  }
}