.tz__vehicles {
  margin: 0 -15px;
  .tz__vehicle {
    .TzInner {
      margin: 0 15px 40px 15px;
      padding: 0 0 3px 0;
      border: none;
      .box-shadow(0 2px 2px 1px rgba(0, 0, 0, 0.1));
    }
    .TzVehicleDescription {
      padding: 0 20px;
      p {
        font-size: 12px;
        margin: 0 0 20px 0;
        font-weight: 300;
        line-height: 1.5em;
      }
    }
    .TzVehicleTitle {
      font-size: 14px;
      line-height: 20px;
      padding: 15px 0;
      margin: 0;
      font-family: 'Montserrat', sans-serif;
      font-weight: 700;
      text-transform: uppercase;
      position: relative;
      a {
        position: relative;
        color: #222222;
        text-decoration: none;
        .transition(all 0.3s linear 0s);
        &:hover {
          padding-left: 10px;
          .transition(all 0.3s linear 0s);
          color: #ff5400;
        }
        &:before {
          position: absolute;
          width: 3px;
          background: #ff5400;
          left: -20px;
          height: 100%;
          top: 50%;
          content: '';
          .translate(0, -50%);
        }
      }
    }

  }
  .tz-top {
    margin: 0 -7px;
    .TzExtrafield-item {
      display: inline-block;
      font-size: 12px;
      padding: 0 7px;
      color: #565656;
      .left, .right {
        display: inline-block;
      }
      .left {
        margin-right: 3px;
      }
      &.tz--block {
        background: #ff5400;
        color: white;
        display: block;
        padding: 5px 15px;
        margin: 0 -17px;
        font-family: 'Montserrat', sans-serif, Arial, sans-serif;
        font-weight: bold;
        font-size: 15px;
      }
      .tz_extrafield-label {
        width: 100%;
      }
    }
  }
  .tz-bottom {
    background: #ff5400;
    display: block;
    padding: 15px;
    margin: 25px -17px 0;
    font-family: 'Montserrat', sans-serif, Arial, sans-serif;
    font-weight: normal;
    font-size: 14px;
    line-height: 12px;
    color: #ffffff;
    letter-spacing: 1px;
    .tz_field_bottom {
      margin-right: 10px;
    }
    .price,
    .price_discount {
      font-weight: 700;
      font-size: 15px;
    }
    .price_discount {
      margin-right: 7px;
      position: relative;
      &:after {
        content: '';
        margin-left: 8px;
        height: 12px;
        top: 1px;
        width: 1px;
        background: rgba(255, 255, 255, 0.5);
        display: inline-block;
        position: relative;
      }
    }
    .has_discount .price {
      font-weight: 400;
      font-size: 13px;
    }
    .tzsymbol {
      margin-right: 1px;
    }
    .TzReadmore {
      float: right;
      color: #ffffff;
      font-size: 12px;
      margin: 0;
      top: 1px;
      position: relative;
      right: -5px;
    }
  }
  .TzPortfolioReadmore {
    margin: 15px 0;
    text-transform: uppercase;
    font-size: 12px;
  }
}

#TzInventory {
  #tz_append {
    a {
      background: #ff5400;
      border-color: #ff5400 !important;
      color: #ffffff;
      font-size: 12px;
      text-decoration: none;
      text-transform: uppercase;
      font-family: 'Montserrat', sans-serif, Arial, sans-serif;
      padding: 10px 20px !important;
      font-weight: 500;
    }
  }
  #tz_options {
    a {
      padding: 7px 15px !important;
      .transition(all 0.3s ease 0.01s);
      &.selected,
      &:hover {
        background: #ff5400;
        border-color: #ff5400 !important;
        color: #ffffff;
      }
    }
  }
  .TzInner.TzList {
    display: flex;
    padding: 0;
    .TzVehicleMedia {
      width: 300px;
      min-width: 300px;
    }
    .TzVehicleDescription {
      width: 100%;
      padding-bottom: 25px;
      p {
        margin-bottom: 10px;
      }
    }
    .tz-bottom {
      margin: 14px -20px 0 0;
      -webkit-border-radius: 3px 0 0 3px;
      -moz-border-radius: 3px 0 0 3px;
      border-radius: 3px 0 0 3px;
      font-size: 14px;
      padding: 10px;
      position: relative;
      z-index: 1;
      .TzExtrafield-item.border-right {
        padding-right: 9px;
        margin-right: 9px;
      }
    }
    .TzPortfolioReadmore {
      font-family: 'Muli', sans-serif, Arial, sans-serif;
      text-transform: none;
      font-weight: normal;
      font-size: 12px;
      background: transparent !important;
      color: #ff5400 !important;
      margin-bottom: 0;
      margin-top: 10px;
      i {
        margin-right: 5px;
      }
    }
  }
}

.Mod_TzFilter {
  input[type='search'],
  input[type='text'] {
    border: 1px solid #dfdfdf;
    width: 100%;
    background: white;
    line-height: 32px;
    font-size: 12px;
    padding: 0 15px;
    height: auto;
  }
  .group-label {
    font-family: 'Montserrat', sans-serif, Arial, sans-serif;
    font-weight: 700;
    color: #222222;
    font-size: 12px;
    text-transform: uppercase;
    line-height: 1em;
    margin-bottom: 9px;
  }
  .checkbox {
    margin: 0;
    font-size: 11px;
    color: #565656;
    font-weight: normal;
    input[type=checkbox] {
      top: -2px;
    }
  }
  ul li {
    border: none;
    padding: 0;
    margin-bottom: 4px;
  }
  .chzn-container-single {
    width: 100% !important;

    .chzn-single {
      border: 1px solid #dfdfdf;
      background: #ffffff;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0;
      .box-shadow(none);
      font-size: 12px;
      color: #bdbdbd;
      line-height: 32px;
      height: auto;
      padding-left: 12px;
      div b {
        background-position: 0 7px;
      }
    }
  }
  .chzn-container-active.chzn-with-drop {
    .chzn-single div b {
      background-position: -18px 7px;
    }
  }
  .chzn-container {
    .chzn-drop {
      border-color: #dfdfdf;
      font-size: 12px;
      color: #bdbdbd;
      .box-shadow(none);
      .chzn-results {
        color: #bdbdbd;
        margin: 0 8px 8px 0;
        padding: 0 0 0 8px;
        li {
          color: #bdbdbd;
          padding: 5px 6px;
          &.highlighted {
            color: #ff5400;
            background: transparent;
          }
        }
      }
    }
  }
  .button {
    background: #222222;
    color: #ffffff;
    font-weight: 700;
    font-family: 'Montserrat', sans-serif, Arial, sans-serif;
    font-size: 14px;
    text-transform: uppercase;
    border: none;
    line-height: 25px;
    padding: 10px 0;
    margin-top: 35px;
    .transition(all 0.3s ease 0s);
    &:hover {
      background: #ff5400;
    }
  }
  .custom_html_filter {
    background: #222;
    color: #fff;
    line-height: 60px;
    margin: -25px -20px 30px;
    padding: 0 20px;
    font-size: 15px;
    text-transform: uppercase;
    font-weight: normal;
    font-family: 'Montserrat', sans-serif, Arial, sans-serif;
    i {
      color: #ff5400;
      margin-right: 15px;
    }
  }
}

/* Detail */
.TzItemVehicleInner {
  .TzArticleTitle {
    margin: 0;
    font-family: 'Montserrat', sans-serif, Arial, sans-serif;
    font-size: 36px;
    color: #222222;
    font-weight: 700;
    margin-bottom: 40px;
    &:before {
      content: '';
      width: 6px;
      background: #ff5400;
      display: inline-block;
      height: 26px;
      margin-right: 5px;
    }
  }
  p {
    margin: 0 0 25px 0;
  }
  .tabs {
    border: none;
    margin: 0;
    li {
      display: inline-block;
      cursor: pointer;
      margin-right: 2px;
      &:first-child {
        margin-left: 5px;
      }
      a {
        display: block;
        cursor: pointer;
        color: #fff;
        background: #565656;
        font-family: 'Montserrat', sans-serif, Arial, sans-serif;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
        border-radius: 0;
        margin-left: 0;
        margin-right: 0;
        border-top: 3px solid transparent !important;
        padding: 15px 20px;
        font-size: 13px;
        font-weight: 400;
        border-left-color: transparent !important;
        border-right-color: transparent !important;
        border-bottom-color: transparent !important;
        &:hover {
          text-decoration: none;
        }
      }
      &.active {
        a {
          border-top: 3px solid #ff5400 !important;
          background: #ffffff;
          border-left-color: transparent;
          border-right-color: transparent;
          border-bottom-color: transparent;
          color: #565656;
          text-decoration: none;
        }
      }
    }
  }
  .tab-content {
    background: #ffffff;
    .box-shadow(0 2px 3px rgba(0, 0, 0, 0.1));
    padding: 30px 25px 0;
    margin-bottom: 35px;
    .tab {
      background: transparent;
    }
  }
  .TzArticleMedia {
    margin-bottom: 20px;
    .tz-slide,
    .tz_portfolio_plus_image {
      border: 3px solid #ffffff;
      .box-shadow(0 2px 3px rgba(0, 0, 0, 0.1));
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0;
    }
    .tz-slide {
      margin: 0 0 5px 0;
    }
    .tz-thumb {
      background: transparent;
      margin: 0;
      border: none;
      ul {
        margin: 5px 0;
      }
      .slides li {
        background: #fff;
        margin-right: 10px;
        border: 3px solid #ffffff;
        .box-shadow(0 2px 3px rgba(0, 0, 0, 0.1));
      }
      .flex-direction-nav {
        margin: 0;
        .flex-direction-nav a:before {
          font-size: 18px;
        }
      }
    }
  }
  .flex {
    display: flex;
    align-items: stretch;
  }
  .tz-price--price {
    background: #ff5400;
    color: #ffffff;
    border: 3px solid #565656;
    font-family: 'Montserrat', sans-serif, Arial, sans-serif;
    padding: 20px 0;
    letter-spacing: 1px;
    .price {
      font-size: 36px;
      font-weight: 700;
      line-height: 1em;
    }
    .price_discount {
      font-size: 36px;
      font-weight: 700;
      margin-right: 15px;
      line-height: 1em;
    }
    .has_discount {
      .price {
        font-size: 12px;
        font-weight: normal;
      }
    }
    .tz_intro {
      font-size: 12px;
      font-family: 'Muli', sans-serif, Arial, sans-serif;
      color: #ffffff;
      margin: 3px 0 0;
      line-height: 1em;
    }
  }
  .tz-info-contact,
  .tz-price {
    width: 50%;
    text-align: center;
    padding: 0 15px;
    margin-bottom: 30px;
    > * {
      .box-shadow(0 2px 3px rgba(0, 0, 0, 0.1));
    }
  }
  .tz-info-contact {
    display: flex;

    align-items: center;
    .tz-content {
      height: 100%;
      background: #565656;
      width: 100%;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 0 30px;
      i {
        font-size: 36px;
        margin-right: 30px;
      }
    }
    .tz-content--content {
      text-align: left;
      span {
        display: block;
        font-size: 13px;
        font-weight: 400;
      }
      .phone-number {
        font-size: 27px;
        font-weight: 700;
        font-family: 'Montserrat', sans-serif, Arial, sans-serif;
      }
    }
  }
}

.tz-specifications {

  .Tz_Extrafield-value,
  .Tz_Extrafield-label {
    display: inline-block;
    margin-bottom: 15px;
  }
  .Tz_Extrafield-label {
    min-width: 105px;
    max-width: 105px;
    color: #868686;
    font-weight: 400;
    text-transform: capitalize;
    word-break: break-all;
  }
  .Tz_Extrafield-value {
    color: #222222;
    font-family: 'Montserrat', sans-serif, Arial, sans-serif;
    font-weight: 500;
  }
  .TzExtrafield-item {
    width: 33%;
    display: inline-block;
  }
}

.TzRelated,
.tz-specifications,
.tz-payment-calculator {
  background: #ffffff;
  padding: 22px 30px 10px;
  margin-bottom: 33px;
  .box-shadow(0 2px 3px rgba(0, 0, 0, 0.1));
  .tz-title {
    position: relative;
    &:after {
      content: '';
      height: 12px;
      width: 3px;
      background: #ff5400;
      left: -30px;
      position: absolute;
      top: 1px;
    }
    h4 {
      font-size: 16px;
      font-family: 'Montserrat', sans-serif, Arial, sans-serif;
      font-weight: 700;
      margin: 0 0 20px -8px;
      display: block;
      position: relative;
      color: #222222;
      span {
        position: relative;
        padding-right: 20px;
        display: inline-block;
        &:before {
          background: #ff5400 none repeat scroll 0 0;
          content: "";
          display: block;
          height: 1px;
          left: 100%;
          margin-top: -3px;
          position: absolute;
          top: 50%;
          width: 30px;
        }
        &:after {
          background: #ff5400 none repeat scroll 0 0;
          content: "";
          display: block;
          height: 1px;
          left: 100%;
          margin-top: 4px;
          position: absolute;
          top: 50%;
          width: 45px;
        }
      }
    }
  }
}

.tz-payment-calculator {
  padding: 22px 30px 30px;
  .tz-block-button {
    text-align: center;
    margin-top: 10px;
    a {
      display: block;
      line-height: 45px;
      color: #ffffff;
      font-size: 14px;
      font-family: 'Montserrat', sans-serif, Arial, sans-serif;
      font-weight: 700;
      background: #222222;
      text-transform: uppercase;
      letter-spacing: 1px;
      text-decoration: none !important;
      .transition(all 0.3s ease 0s);
      &:hover {
        background: #ff5400;
      }
    }
  }
  .tz-content {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 -12px;
    > * {
      width: 50%;
      padding: 0 12px 20px 12px;
    }
    label {
      display: block;
      font-size: 12px;
      font-family: 'Montserrat', sans-serif, Arial, sans-serif;
      color: #222222;
      text-transform: uppercase;
      span {
        color: #ff5400;
      }
    }
    select,
    input {
      width: 100%;
      border: 1px solid #dfdfdf;
      color: #bdbdbd;
      font-size: 12px;
      line-height: 34px;
      padding: 0 15px;
      height: 34px;
    }
    .chzn-container-single .chzn-single {
      height: auto;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0;
      background: transparent;
      .box-shadow(none);
      border: 1px solid #dfdfdf;
      line-height: 34px;
      color: #222222;
      font-size: 12px;
      padding: 0 15px;
      div b {
        background-position-y: 8px;
      }
    }
    .chzn-container-active.chzn-with-drop .chzn-single div b {
      background-position-y: 9px;
    }
    .chzn-container .chzn-drop {
      border-color: #dfdfdf;
    }
    .chzn-container .chzn-results {
      margin: 0 9px 5px 0;
      padding: 0 0 0 9px;
      font-size: 12px;
    }
  }
  .payment_result {
    margin: 0;
    display: flex;
    align-items: center;
    background: #ff5400;
    color: #ffffff;
    padding: 0;
    .transition(all 0.3s linear);
    -moz-transform: scale(0.2) translateY(-100px);
    -webkit-transform: scale(0.2) translateY(-100px);
    -o-transform: scale(0.2) translateY(-100px);
    -ms-transform: scale(0.2) translateY(-100px);
    transform: scale(0.2) translateY(-100px);
    opacity: 0;
    height: 0;
    &.active {
      padding: 15px 23px;
      margin: 30px 0 0;
      .transition(all 0.3s linear);
      -moz-transform: scale(1) translateY(0px);
      -webkit-transform: scale(1) translateY(0px);
      -o-transform: scale(1) translateY(0px);
      -ms-transform: scale(1) translateY(0px);
      transform: scale(1) translateY(0px);
      opacity: 1;
      //padding: 15px;
      height: auto;
    }
    > * {
      width: 33.33%;
    }
    .title, .value {
      display: block;
      letter-spacing: 1px;
    }
    .title {
      font-size: 12px;
    }
    .value {
      font-family: 'Montserrat', sans-serif, Arial, sans-serif;
      font-weight: 700;
      font-size: 12px;
    }
  }
}

.TzRelated {
  ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0;
    li {
      width: 25%;
      margin-bottom: 15px;
    }
  }
}

.tz-features-options {
  .TzExtrafield-Label {
    font-size: 18px;
    color: #565656;
    font-family: 'Muli', sans-serif, Arial, sans-serif;
    text-transform: capitalize;
    display: inline-block;
    position: relative;
    padding-bottom: 5px;
    font-weight: 700;
    &:before {
      width: 30px;
      content: " ";
      right: -45px;
      height: 1px;
      background: #ff5400;
      top: 10px;
      position: absolute;
    }
    &:after {
      width: 45px;
      content: " ";
      right: -60px;
      height: 1px;
      background: #ff5400;
      position: absolute;
      top: 17px;
    }
  }
  ol {
    margin-bottom: 30px;
    li {
      text-align: left;
      border-bottom: 1px dashed #e8e8e8;
      line-height: 30px;
      padding: 6px 0;
      strong {
        float: right;
        font-family: 'Montserrat', sans-serif;
      }
    }
  }
}

.su-list {
  position: relative;
  padding-left: 20px;
  font-size: 13px;
  line-height: 30px;
}

.su-list:before {
  content: "\f046";
  position: absolute;
  left: 0;
  top: 1px;
  font-size: 13px;
  color: #ff5400;
  font-family: 'FontAwesome';
}

#contact-form-article {
  margin: 30px -15px 0 !important;
  input[type="text"],
  input[type="email"],
  input[type="tel"],
  textarea {
    line-height: 33px;
    width: 100%;
    padding: 0 15px;
    border: 1px solid #dfdfdf;
    font-size: 12px;
  }
  .form-group {
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 17px;
    label {
      text-transform: uppercase;
      font-weight: 400;
      font-size: 12px;
      font-family: 'Montserrat', sans-serif, Arial, sans-serif;
      color: #222222;
    }
  }
  .form-actions {
    .tz-contact-send {
      background: #222222;
      color: #ffffff;
      border: 1px solid #222222;
      line-height: 33px;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0;
      font-size: 12px;
      padding: 0 15px !important;
    }
  }
}