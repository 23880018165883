.tz_service_icon {
  .sppb-icon-container {
    position: relative;
    &:after {
      border-left: 6px solid rgba(0, 0, 0, 0);
      border-right: 6px solid rgba(0, 0, 0, 0);
      border-top: 6px solid #ff5400;
      bottom: -5px;
      content: "";
      left: 50%;
      margin-left: -6px;
      position: absolute;
    }
  }
}

.tz_service_icon--2 {
  //@media (max-width: 991px) {
  margin-bottom: 20px;
  //}
  .sppb-icon {
    //background: rgba(247,249,251,0.2);
  }
  .sppb-img-container {
    width: 70px;
    height: 70px;
    line-height: 70px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    background: rgba(247, 249, 251, 0.2);
    text-align: center;
    @media (max-width: 991px) {
      margin-top: 0 !important;
    }
  }
  .sppb-addon-text {
    font-size: 14px;
    font-weight: 200;
    letter-spacing: 1px;
    line-height: 24px;
  }
  .sppb-addon-title {
    @media (max-width: 991px) {
      margin-bottom: 5px !important;
    }
  }
  .sppb-media {
    > .pull-right {
      margin-left: 30px;
    }
    > .pull-left {
      margin-right: 30px;
    }
  }
}

.autoshowroom-title {
  .sppb-addon-title {
    position: relative;
    font-size: 25px;
    color: #222222;
    text-align: center;
    //position: relative;
    padding-bottom: 22px;
    margin-bottom: 30px;
    @media (max-width: 560px) {
      font-size: 20px;
    }
    &:before {
      position: absolute;
      content: "";
      bottom: 0;
      left: 50%;
      width: 60px;
      height: 4px;
      border-top: 1px solid #ff5400;
      border-bottom: 1px solid #ff5400;
      margin-left: -30px;
    }
  }
  &.sppb-text-left {
    .sppb-addon-title {
      text-align: left;
      &:before {
        left: 0;
        margin-left: 0;
      }
    }
  }
}

.sppb-addon-ajax-contact {
  background: #f7f9fb;
  .sppb-ajaxt-contact-form {
    padding-top: 25px;
  }
  .sppb-form-group {
    padding: 5px 20px;
    margin: 0;
  }
  button[type='submit'] {
    margin-top: 25px;
    width: 100%;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    background: #ff5400;
    font-weight: 700;
    color: #ffffff;
    text-align: center;
    text-transform: uppercase;
    padding: 10px 16px;
  }
  textarea,
  input {
    width: 100%;
    padding: 10px 16px;
    font-family: 'Muli', sans-serif;
    font-size: 14px;
    color: #bdbdbd;
    background: #ffffff;
    border: 1px solid #e9e9e9;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    .box-shadow(none);
  }
  input {
    height: 45px !important;
    line-height: 45px;
  }
  textarea {
    max-height: 100px;
  }
}

.autoshowroom-sign-up-box {
  position: relative;
  background: #ff5400;
  padding: 30px 0 25px;

  h3.autoshowroom-sign-up-title {
    font-size: 20px;
    color: #ffffff;
    text-align: center;
    letter-spacing: 1px;
    margin-bottom: 8px;
  }
  .autoshowroom-sign-up-subtitle {
    font-size: 15px;
    color: #ffffff;
    text-align: center;
    margin: 0;
  }
  .autoshowroom-sign-up-triangle {
    height: 40px;
    width: 100%;
    background: #ff5400;
    overflow: hidden;
    position: relative;
    &:before {
      background: #f7f9fb none repeat scroll 0 0;
      content: "";
      display: block;
      height: 100px;
      left: 50%;
      position: absolute;
      top: 0;
      transform: rotate(-16deg);
      -webkit-transform: rotate(-16deg);
      -moz-transform: rotate(-16deg);
      -ms-transform: rotate(-16deg);
      -o-transform: rotate(-16deg);
      width: 100%;
    }
    &:after {
      background: #f7f9fb none repeat scroll 0 0;
      content: "";
      display: block;
      height: 100px;
      left: -50%;
      position: absolute;
      top: 0;
      transform: rotate(16deg);
      -webkit-transform: rotate(16deg);
      -moz-transform: rotate(16deg);
      -ms-transform: rotate(16deg);
      -o-transform: rotate(16deg);
      width: 100%;
    }
  }
}

.tz-testimonial {
  .tz-item {
    .tz-avatar {
      width: auto;
      display: inline-block;
      max-width: 100px;
      border: 3px solid #e9e9e9;
      filter: url(data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter i…0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale);
      -webkit-filter: grayscale(100%);
      -moz-filter: grayscale(100%);
      -ms-filter: grayscale(100%);
      filter: grayscale(100%);
      filter: gray;
      .transition(all 0.3s ease 0s);
    }
    .tz-img-responsive {
      display: inline-block;
      position: relative;
      &:after {
        border-left: 6px solid rgba(0, 0, 0, 0);
        border-right: 6px solid rgba(0, 0, 0, 0);
        border-top: 6px solid #ff5400;
        bottom: -5px;
        content: "";
        left: 50%;
        margin-left: -6px;
        visibility: hidden;
        opacity: 0;
        position: absolute;
        transition: all 300ms ease 0s;
        -webkit-transition: all 300ms ease 0s;
        -moz-transition: all 300ms ease 0s;
        -ms-transition: all 300ms ease 0s;
        -o-transition: all 300ms ease 0s;
      }
    }
  }
  .fa {
    font-size: 30px;
    //font-weight: 700;
    font-style: normal;
    color: #ff5400;
    line-height: 35px;
  }
  .tz-item-content {
    padding-top: 80px;
    visibility: hidden;
    opacity: 0;
    .transition(all 0.3s ease 0s);
    width: 840px;
    .translate(-50%, 0);
    left: 50%;
    position: relative;
    @media (max-width: 991px) {
      width: 600px;
    }
    @media (max-width: 767px) {
      width: 300px;
      .tz-testimonial-message {
        font-size: 16px;
        line-height: 28px;
      }
    }
  }
  .tz-testimonial-message {
    font-family: 'Muli', sans-serif;
    font-size: 18px;
    font-weight: 300;
    font-style: italic;
    color: #565656;
    line-height: 34px;
    margin-bottom: 35px;
    margin-top: 20px;
  }
  .tz-testimonial-client {
    color: #bdbdbd;
  }
  .client-name {
    color: #ff5400;
    font-size: 14px;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif, Arial, sans-serif;
    letter-spacing: 2px;
  }
  .client-position {
    font-size: 13px;
    font-weight: normal;
    color: #bdbdbd;
    letter-spacing: 1px;
  }
  .owl-item.active.center {
    .tz-item-content {
      visibility: visible;
      opacity: 1;
    }
    .tz-avatar {
      -webkit-filter: none;
      -moz-filter: none;
      -ms-filter: none;
      filter: none;

      border-color: #ff5400;
      position: relative;

    }
    .tz-img-responsive {
      .scale(1.7);
      &:after {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  .owl-item {
    .transition(all 0.3s ease 0s);
    @media (max-width: 767px) {
      text-align: center !important;
    }
  }
  .owl-dots {
    margin-top: 35px !important;
    div.owl-dot {
      height: 14px;
      width: 14px;
      margin: 0 8px;
      &.active, &:hover {
        span {
          width: 14px;
          height: 14px;
          margin: 0;
          background: #ff5400;
        }
      }
      span {
        height: 6px;
        width: 6px;
        margin: 4px auto;
        padding: 0;
        background: #ff5400;
        .transition(all 0.3s ease 0s);
      }
    }
  }
  &.tz-padding {
    padding: 0 140px;
    @media (max-width: 991px) {
      padding: 0 15px;
    }
    .tz-item {
      padding-top: 35px;
    }
  }
}

.sppb-addon-animated-number {
  .tz-icon {
    position: absolute;
    top: -5px;
    color: rgba(255, 255, 255, 0.1);
    font-size: 90px;
    line-height: 1em;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: -1;
  }
  .sppb-addon-content {
    display: block;
    padding: 45px 0;
  }
  .sppb-animated-number {
    font-family: 'Montserrat', sans-serif, Arial, sans-serif;
    font-weight: 700;
  }
  .sppb-animated-number-title {
    font-family: 'Muli', sans-serif, Arial, sans-serif;
    font-weight: 700;
  }
}

.sppb-addon-image-content {
  .sppb-row {
    display: flex;
    align-items: center;
    @media (max-width: 767px) {
      display: block;
      .tz-content {
        padding: 0 30px !important;
        position: absolute;
        bottom: 0;
        width: 100%;
        left: 0;
      }

      .tz-image {
        width: 100%;
      }
    }
  }
  .sppb-image-content-title {
    font-weight: 700;
  }
  .tz-image {
    padding: 0;
    width: 58%;
    opacity: 0.1;
    z-index: -1;
    position: relative;
  }
  .sppb-image-content-text {
    color: #ffffff;
  }
  .tz-content {

  }
  &.aligment-left {
    .tz-image {
      order: 1;
    }
    .tz-content {
      order: 2;
      padding: 0 60px 0 20px;
    }
  }
  &.aligment-right {
    .tz-image {
      order: 2;
    }
    .tz-content {
      order: 1;
      padding: 0 20px 0 60px;
    }
  }
}

.tz-carousel-full-width {
  overflow: hidden;
  margin: 0 -15px;
  .owl-carousel .owl-stage-outer {
    overflow: visible;
    //padding: 10px 0;
    &:after {
      content: '';
      width: 10000px;
      position: absolute;
      right: -10030px;
      height: 100%;
      background: rgba(255, 255, 255, 0.8);
      top: 0;
      z-index: 1;
    }
    &:before {
      content: '';
      width: 10000px;
      position: absolute;
      left: -10030px;
      height: 100%;
      background: rgba(255, 255, 255, 0.8);
      top: 0;
      z-index: 1;
    }
  }
  .sppb-addon-content {
    max-width: 1200px;
    margin: auto;
    padding: 0 15px;
  }
}

.tz-mod-vehicle {
  .tzpp_content {
    background: #ffffff;
    padding-top: 26px;
    padding-bottom: 3px;
    .box-shadow(0 2px 3px rgba(0, 0, 0, 0.1));
  }
  .title {
    font-size: 14px;
    color: #222222;
    line-height: 15px;
    margin-bottom: 15px;
    &:hover {
      a {
        color: #ff5400;
      }
    }
    &:before {
      content: '';
      height: 15px;
      width: 3px;
      background: #ff5400;
      top: 2px;
      position: relative;
      display: inline-block;
      margin-right: 18px;
    }
    a {
      color: #222222;
    }
  }
  .description {
    padding: 0 20px 15px 20px;
    p {
      display: inline-block;
      font-size: 13px;
      font-weight: 600;
      margin-bottom: 10px;
    }
  }
  .tz_field_top {
    display: flex;
    flex-wrap: wrap;
    margin: 0 1px 0 3px;

    .tz_field {
      text-align: center;
      width: 33.33%;
      font-size: 14px;
      padding: 10px 0;
      border-right: 1px solid #ffffff;
      background: #f1f1f1;
      color: #565656;
      i {
        margin-right: 5px;
      }
      div {
        display: inline-block;
      }
    }
  }
  .tzpp_media {
    position: relative;
  }
  .tz_field_bottom {
    position: absolute;
    bottom: 25px;
    left: 0;
    z-index: 1;
    padding: 10px 18px;
    background: #ff5400;
    font-family: 'Montserrat', sans-serif, Arial, sans-serif;
    text-transform: uppercase;
    font-weight: 700;
    color: #ffffff;
    .tz_field {
      display: inline-block;
    }
    .price,
    .price_discount {
      font-weight: 700;
      font-size: 15px;
    }
    .price_discount {
      margin-right: 8px;
      position: relative;
      &:after {
        content: '';
        margin-left: 8px;
        height: 12px;
        top: 1px;
        width: 1px;
        background: rgba(255, 255, 255, 0.5);
        display: inline-block;
        position: relative;
      }
    }
    .has_discount .price {
      font-weight: 400;
      font-size: 13px;
    }
  }
  &.owl-theme .owl-nav {
    margin: 0;
    > div {
      position: absolute;
      top: 50%;
      .translate(0, -50%);
      height: 45px;
      width: 45px;
      text-align: center;
      line-height: 45px;
      font-size: 18px;
      color: #ffffff;
      margin: 0;
      padding: 0;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0;
      background: #222222;
      .box-shadow(none);
      &:hover {
        background: #ff5400;
      }
      &.owl-prev {
        left: -125px;
        @media (max-width: 1400px) {
          left: -45px;
        }
        @media (max-width: 1199px) {
          left: 0;
          margin-top: -20px;
        }
        @media (max-width: 991px) {
          left: 0;
          margin-top: 5px;
        }
      }
      &.owl-next {
        right: -125px;
        @media (max-width: 1400px) {
          right: -45px;
        }
        @media (max-width: 1199px) {
          right: 0;
          margin-top: -20px;
        }
        @media (max-width: 991px) {
          right: 0;
          margin-top: 5px;
        }
      }
    }
  }
  .masonry {
    margin: 0 -15px;
    .TzInner {
      padding: 20px 15px;
    }
  }
  &.TzContent {
    margin: 0 -15px;
    overflow: hidden;
    @media (max-width: 991px) {
      padding: 0 15px;
    }
    .element {
      background: transparent;
    }
  }
  .option-combo {
    margin-bottom: 15px;
    a {
      background: transparent;
      border: none;
      font-family: 'Muli', sans-serif, Arial, sans-serif;
      text-transform: uppercase;
      font-weight: 700;
      color: #222222;
      font-size: 14px;
      padding: 0 20px;
      @media (max-width: 767px) {
        font-size: 13px;
        padding: 0 10px;
      }
      @media (max-width: 400px) {
        font-size: 12px;
        padding: 0 5px;
      }
      &:hover, &.selected {
        color: #ff5400;
      }
    }
  }
}

div.sppb-addon-single-image-container {
  display: block;
}

/*---- Revolution ----*/
.tparrows.preview1 {
  width: 72px;
  height: 72px;
  &:after {
    width: 72px;
    height: 72px;
    line-height: 72px;
    font-family: FontAwesome;
    color: #252525;
    background: #ffffff;
  }
  &:hover:after {
    background: #222222;
    color: #ffffff;
  }
  &.tp-rightarrow:after {
    content: '\f054';
  }
  &.tp-leftarrow:after {
    content: '\f053';
  }
  .tp-arr-imgholder {
    width: 150%;
  }
  @media (max-width: 767px) {
    height: 40px;
    width: 40px;
    &:after {
      width: 40px;
      height: 40px;
      line-height: 40px;
    }
  }
}

.revo-auto-showroom-title {
  font-family: 'Montserrat', sans-serif, Arial, sans-serif;
  font-size: 55px;
  color: #ffffff;
  font-weight: 700;
  line-height: 1em;
  &:before {
    content: '';
    width: 4px;
    height: 39px;
    top: 1px;
    position: relative;
    margin-right: 17px;
    display: inline-block;
    background: #ff5400;
    @media (max-width: 991px) {
      height: 28px;
    }
    @media (max-width: 767px) {
      height: 15px;
      margin-right: 10px;
    }
  }
  &.splitted {
    .tp-splitted {
      display: inline-block !important;
    }
  }
}

.revo-auto-showroom-title1 {
  font-size: 36px;
  font-family: 'Montserrat', sans-serif, Arial, sans-serif;
  color: #ffffff;
  font-weight: 700;
  line-height: 0.75em;
  border-left: 4px solid #ff5400;
  padding-left: 15px;
  text-transform: uppercase;
  p {
    margin: 30px 0 0;
    color: #ffffff;
  }
  span {
    color: #222222;
    &.white {
      color: #ffffff;
      position: relative;
      top: -20px;
      margin-right: 10px;
      font-weight: 700;
      font-size: 24px;
      @media (max-width: 480px) {
        top: -5px;
      }
    }
  }
  .price {
    font-size: 48px;
    font-family: 'Montserrat', sans-serif, Arial, sans-serif;
    font-weight: 700;
  }
  .price1 {
    color: #ff5400;
    font-size: 48px;
    font-family: 'Montserrat', sans-serif, Arial, sans-serif;
    font-weight: 700;
  }
  .muli-bold-white {
    font-family: 'Muli', sans-serif, Arial, sans-serif;
    font-weight: 700;
    color: #ffffff;
  }
}

.revo-auto-showroom-new {
  background: #ff5400;
  color: #ffffff;
  font-family: 'Montserrat', sans-serif, Arial, sans-serif;
  font-weight: 700;
  font-size: 18px;
  text-transform: uppercase;
  padding: 5px 18px;
  letter-spacing: 1px;
}

.revo-auto-showroom-price {
  font-family: 'Muli', sans-serif, Arial, sans-serif;
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 700;
  color: #ffffff;
  span {
    color: #ff5400;
  }
}

.revo-auto-showroom-desc {
  font-family: 'Muli', sans-serif, Arial, sans-serif;
  font-size: 18px;
  font-weight: 300;
  line-height: 27px;
  color: #ffffff;
  &.style-2 {
    font-size: 15px;
  }
}

.revo-auto-showroom-btn a {
  background: #ff5400;
  color: #ffffff;
  font-size: 14px;
  font-family: 'Muli', sans-serif, Arial, sans-serif;
  text-transform: uppercase;
  padding: 15px 30px;
  letter-spacing: 2px;
  display: inline-block;
  font-weight: 500;
  .transition(all 0.3s ease 0s);
  &:hover {
    background: #222222;
    color: #ffffff;
  }
}

.tp-dottedoverlay.twoxtwo {
  background: rgba(46, 46, 46, 0.3);
}

/*---- Magazine ----*/
.tz_item--magazine {
  position: relative;

  &:hover {
    .tz_content .tz_right {
      top: -90px;
    }
  }
  .tz_image {
    position: relative;
  }
  .mod-articles-category-date {
    position: absolute;
    top: 0;
    right: 0;
    background: #ff5400;
    color: #ffffff;
    display: flex;
    font-size: 13px;
    height: 30px;
    line-height: 30px;
    span {
      padding: 0 10px;
      display: inline-block;
    }
    i {
      width: 30px;
      height: 30px;
      text-align: center;
      line-height: 30px;
      color: #ff5400;
      background: #ffffff;
      font-size: 16px;
    }
  }
  .tz_content {
    width: 100%;
    position: relative;

    .tz_left {
      width: 70px;
      text-align: center;
      background: #ffffff;

      min-height: 140px;
      margin: 0 1px;
      .box-shadow(0 1px 3px rgba(0, 0, 0, 0.14));
      > * {
        display: block;
        height: 70px;
        padding: 15px 0;
        border-bottom: 1px solid #f3f3f3;
        i {
          display: block;
          color: #bdbdbd;
          margin-bottom: 5px;
        }
      }
    }
    .tz_right {
      right: 0;
      left: 70px;
      position: absolute;
      background: #ffffff;
      top: -50px;
      margin-top: 30px;
      bottom: 0;
      padding: 22px 20px 15px;
      .box-shadow(-1px 0 3px rgba(0, 0, 0, 0.14));
      border-right: 1px solid rgba(0, 0, 0, 0.05);
      .transition(all 0.3s ease 0s);
      .tz_info {
        height: 100%;
        overflow: hidden;
      }
    }
    .mod-articles-category-title {
      line-height: 18px;
      font-family: 'Muli', sans-serif, Arial, sans-serif;
      font-size: 14px;
      font-weight: 700;
      color: #222222;
      letter-spacing: 1px;
      display: block;
      position: relative;
      &:before {
        content: '';
        display: block;
        width: 3px;
        height: 14px;
        background: #ff5400;
        position: absolute;
        top: 1px;
        left: -20px;
      }
    }
    .tz_front_info {
      margin-bottom: 15px;
      font-size: 13px;
      font-style: italic;
      color: #222222;
      a {
        font-size: 13px;
        color: #ff5400;
      }
      span {
        &:first-child {
          border-right: 1px solid #e7e7e7;
          margin-right: 10px;
          padding-right: 10px;
        }
      }
    }
    .mod-articles-category-introtext {
      margin: 10px 0;
      line-height: 22px;
      font-size: 13px;
      font-weight: 600;
    }
    .mod-articles-category-readmore {
      padding-top: 5px;
      a {
        font-size: 14px;
        font-weight: 600;
        color: #ff5400;
      }
    }
  }
}

.tz_item--magazines {
  .owl-stage-outer {
    padding-bottom: 3px;
  }
  .owl-dots {
    div.owl-dot {
      height: 14px;
      width: 14px;
      margin: 0 8px;
      &.active, &:hover {
        span {
          width: 14px;
          height: 14px;
          margin: 0;
          background: #ff5400;
        }
      }
      span {
        height: 6px;
        width: 6px;
        margin: 4px auto;
        padding: 0;
        background: #ff5400;
        .transition(all 0.3s ease 0s);
      }
      @media (max-width: 480px) {
        margin: 0 5px;
      }
    }
  }

  .owl-theme {
    padding-bottom: 77px;
    .owl-dots {
      position: absolute;
      bottom: 11px;
      right: 45px;
      left: 45px;
    }
    .owl-nav {
      margin: 0;
      > div {
        position: absolute;
        //top: 50%;
        //.translate(0, -50%);
        bottom: 0;
        height: 45px;
        width: 45px;
        text-align: center;
        line-height: 45px;
        font-size: 18px;
        color: #ffffff;
        margin: 0;
        padding: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
        background: #222222;
        .box-shadow(none);
        &:hover {
          background: #ff5400;
        }
        &.owl-prev {
          left: 0;
        }
        &.owl-next {
          right: 0;
        }
        @media (max-width: 480px) {
          bottom: 6px;
          height: 35px;
          width: 35px;
          line-height: 35px;
        }
      }
    }
  }
}

/*--- Tz Filter Inline ---*/
.tz-filter-inline {
  form {
    display: flex;
    flex-wrap: wrap;
  }
  .form-group,
  .tz-form-group {
    width: 25%;
    //overflow: hidden;
    margin: 0;
    padding: 5px 6px;
    label {
      display: none;
    }
    textarea {
      max-height: 40px;
    }
    &:first-child {
      padding-left: 0;
    }
    input, textarea, select {
      border: 1px solid #dfdfdf;
      background: #ffffff;
      color: #bdbdbd;
      line-height: 25px;
      padding: 10px 15px;
    }
    .chzn-container-single {
      display: block;
      width: 100% !important;
      margin: 0;
      .chzn-single {
        height: auto;
        padding: 10px 15px;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
        border: 1px solid #dfdfdf;
        background: #ffffff;
        .box-shadow(none);
        color: #bdbdbd;
        div {
          top: 10px;
          right: 10px;
          width: 15px;
        }
      }
      .chzn-drop {
        border: 1px solid #dfdfdf;
        color: #bdbdbd;
        margin: -1px 10px 5px 0;
        padding: 10px 10px 5px 10px;
        .box-shadow(none);
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
      }
      .chzn-results {
        color: #bdbdbd;
      }
    }
    @media (max-width: 767px) {
      width: 50%;
      &:first-child {
        padding-left: 6px;
      }
    }
  }
  .tz-fileds {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 85%;
    @media (max-width: 767px) {
      width: 100%;
    }
  }

  .button {
    background: #222;
    border: none;
    box-shadow: none;
    line-height: 44px;
    width: 100%;
    padding: 0;
    margin: 0;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 13px;
    &:hover {
      color: #ffffff;
      background: #ff5400;
    }
  }
  .tz-button {
    padding-left: 15px;
    width: 15%;
    float: right;
    margin-top: 5px;
    order: 2;
    @media (max-width: 767px) {
      width: 100%;
      padding: 0 7px;
    }
  }
  @media (max-width: 767px) {
    margin: 0 -6px;
  }
}

.tz-filter-vertical {
  background: #ffffff;

  form {
    padding: 15px 30px 25px 30px;
    @media (max-width: 767px) {
      .box-shadow(0 1px 3px rgba(0, 0, 0, 0.1));
    }
    label {
      text-transform: uppercase;
      font-weight: 600;
      color: #222222;
      display: block;
      margin-bottom: 5px;
      font-size: 13px;
    }
    textarea, input {
      border: 1px solid #dfdfdf;
      background: transparent;
      color: #bdbdbd;
    }
    .chzn-container-single {
      display: block;
      width: 100% !important;
      margin: 0;
      .chzn-single {
        height: auto;
        padding: 10px 15px;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
        border: 1px solid #dfdfdf;
        background: #ffffff;
        .box-shadow(none);
        color: #bdbdbd;
        div {
          top: 10px;
          right: 10px;
          width: 15px;
        }
      }
      .chzn-drop {
        border: 1px solid #dfdfdf;
        color: #bdbdbd;
        margin: -1px 10px 5px 0;
        padding: 10px 10px 5px 10px;
        .box-shadow(none);
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
      }
      .chzn-results {
        color: #bdbdbd;
      }
    }
    .tz-form-group {
      margin-bottom: 15px;
    }
  }
  h3 {
    background: #f7f9fb;
    border-top: 3px solid #ff5400;
    font-family: 'Montserrat', sans-serif, Arial, sans-serif;
    font-weight: 700;
    font-size: 14px;
    color: #ff5400;
    line-height: 18px;
    padding: 16px 30px;
  }
  .tz-button {
    margin-top: 45px;
  }
  .button {
    background: #222;
    border: none;
    box-shadow: none;
    line-height: 44px;
    //width: 100%;
    padding: 0 35px;
    margin: 0;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 13px;
    @media (max-width: 767px) {
      line-height: 35px;
    }
    &:hover {
      color: #ffffff;
      background: #ff5400;
    }
  }
}

/*--- Slide Home 3 ---*/

.tz-slide-home-3 {
  background: #f7f9fb;
  .tz_portfolio_plus_image {
    height: 96vh;
    max-height: 920px;
    min-height: 920px;
    background-size: cover !important;
    @media (max-width: 1199px) {
      max-height: inherit;
      min-height: 0;
    }
  }
  .tz__block--title {
    position: absolute !important;
    top: 250px;
    left: 50%;
    z-index: 111;
    margin-left: -585px;
    border-left: 4px solid #ff5400;
    padding: 0 15px 0 15px;
    @media (max-width: 991px) {
      margin-left: -440px;
    }
    @media (max-width: 800px) {
      margin-left: -355px;
      width: auto;
    }
    @media (max-width: 767px) {
      margin-left: -160px;
      max-width: 320px;
      top: 50%;
      .translate(0, -50%);
      margin-top: -80px;
    }
    .title {
      margin: 0;
      padding: 0;
      a {
        font-size: 36px;
        color: #fff;
        text-transform: uppercase;
        display: block;
        line-height: 30px;
        padding-bottom: 15px;
        padding-left: 0;
        border: none;
        @media (max-width: 767px) {
          font-size: 18px;
          padding-bottom: 5px;
        }
      }
    }
    .tz_field_bottom {
      color: #ffffff;
      .price {
        font-weight: 700;
        font-size: 48px;
        color: #ff5400;
        font-family: 'Montserrat', sans-serif, Arial, sans-serif;
        line-height: 40px;
        @media (max-width: 767px) {
          font-size: 30px;
          line-height: 25px;
        }
        span {
          font-size: 24px;
          font-family: 'Muli', sans-serif, Arial, sans-serif;
          color: #fff;
          position: relative;
          top: -15px;
          margin-right: 10px;
          @media (max-width: 767px) {
            font-size: 14px;
            top: -10px;
          }
        }
      }

    }
  }
  .tzpp_content {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
    width: 800px;
    background: #ff5400;
    padding: 35px 35px 0;
    * {
      color: #ffffff;
    }
    @media (max-width: 1199px) {
      width: 60%;
    }
    @media (max-width: 991px) {
      right: 13px;
      width: auto;
      left: 13px;
      bottom: 20px;
      .box-shadow(0 2px 3px rgba(0, 0, 0, 0.1));
    }
    @media (max-width: 767px) {
      padding: 25px 20px 0;
    }
    @media (max-width: 480px) {
      position: relative;
      right: 0;
      left: 0;
      bottom: 0;
      margin: 0px -15px 30px;
    }
  }
  .title {
    margin-bottom: 35px;
    @media (max-width: 480px) {
      margin-bottom: 10px;
    }
    a {
      color: #fff;
      font-size: 24px;
      font-family: 'Montserrat', sans-serif, Arial, sans-serif;
      font-weight: 700;
      text-transform: uppercase;
      @media (max-width: 480px) {
        font-size: 18px;
      }
    }
  }
  .container {
    position: relative;
  }
  .tzpp_media {
    padding-bottom: 90px;
    background: #f7f9fb;
    @media (max-width: 480px) {
      padding: 0;
    }
  }
  .tz_field_top {
    background: #ffffff;
    padding: 0 20px;
    margin: 0 -35px;
    @media (max-width: 767px) {
      margin: 0 -20px;
    }
    @media (max-width: 480px) {
      padding: 0 10px;
    }
    .tz_field {
      display: inline-block;
      font-weight: normal;
      color: #222222;
      padding: 35px 15px;
      i {
        margin-right: 5px;
      }
      * {
        color: #222222;
        display: inline-block;
      }
      @media (max-width: 480px) {
        padding: 20px 10px;
      }
    }
  }
  &.owl-theme {
    //padding-bottom: 77px;
    .owl-dots {
      position: absolute;
      bottom: 11px;
      right: 45px;
      left: 45px;
    }
    .owl-nav {
      margin: 0;
      > div {
        position: absolute;
        top: 50%;
        .translate(0, -50%);
        height: 72px;
        width: 72px;
        text-align: center;
        line-height: 72px;
        font-size: 30px;
        color: #ffffff;
        margin: 0;
        padding: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
        background: #222222;
        .box-shadow(none);
        &:before {
          font-size: 30px;
          line-height: 72px;
        }
        &:hover {
          background: #ff5400;
        }
        &.owl-prev {
          left: 0;
        }
        &.owl-next {
          right: 0;
        }
        @media (max-width: 767px) {
          height: 40px;
          width: 40px;
          line-height: 40px;
          font-size: 18px;
          margin-top: -50px;
          &:before {
            font-size: 18px;
            line-height: 40px;
          }
        }
      }
    }
  }
}

.tz-filter-on-slide {
  position: absolute;
  max-width: 340px;
  left: 50%;
  margin-left: -585px;
  bottom: 0;
  z-index: 99;
  @media (max-width: 1199px) {
    max-width: 300px;
    margin-left: -420px;
  }
  @media (max-width: 991px) {
    position: relative;
    max-width: none;
    margin: 30px 23px 30px;
    left: 0;
    .box-shadow(0 2px 3px rgba(0, 0, 0, 0.2));
  }
  @media (max-width: 767px) {
    margin: 30px 15px;
  }
}

/*--- Filter Home Slide 1 ---*/
.tz-fix-position-filter {
  position: absolute;
  top: 150px;
  left: 50%;
  transform: translate(50%, 0);
  z-index: 9999;
  margin-left: 65px;
  max-width: 340px;
}

.tz-filter {
  .tz__auto__showroom--field {
    select {
      width: 50%;
      display: inline-block;
    }
    .chzn-container-single {
      width: 49% !important;
      display: inline-block;
    }
  }
}

/*--- Filter Background Gradient ---*/
.tz-filter-bg-gradient {
  padding: 50px 15px;
  margin: 0 -15px;
  background: rgba(255, 84, 0, 0.8);
  position: relative;
  &:before {
    content: '';
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    //z-index: -2;
    opacity: 0.8;
  }
  &:after {
    content: '';
    opacity: 0.8;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    background: rgba(255, 85, 0, 0.5);
    background: -moz-linear-gradient(-45deg, rgba(255, 85, 0, 0.5) 0%, rgba(48, 109, 113, 0.5) 100%);
    background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(255, 85, 0, 0.5)), color-stop(100%, rgba(48, 109, 113, 0.5)));
    background: -webkit-linear-gradient(-45deg, rgba(255, 85, 0, 0.5) 0%, rgba(48, 109, 113, 0.5) 100%);
    background: -o-linear-gradient(-45deg, rgba(255, 85, 0, 0.5) 0%, rgba(48, 109, 113, 0.5) 100%);
    background: -ms-linear-gradient(-45deg, rgba(255, 85, 0, 0.5) 0%, rgba(48, 109, 113, 0.5) 100%);
    background: linear-gradient(135deg, rgba(255, 85, 0, 0.5) 0%, rgba(48, 109, 113, 0.5) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff5500', endColorstr='#306d71', GradientType=1);
  }
  .tz-form-group {
    input, select {
      background: rgba(255, 255, 255, 0.2);
      border: none;
      color: #ffffff;
      font-size: 14px;
      &::-webkit-input-placeholder {
        color: #fff;
      }
      &::-moz-placeholder {
        color: #fff;
      }
      &:-ms-input-placeholder {
        color: #fff;
      }
      &:-moz-placeholder {
        color: #fff;
      }
    }
    .chzn-container-single .chzn-single {
      background: rgba(255, 255, 255, 0.2);
      border: none;
      font-size: 14px;
      span {
        color: #ffffff;
      }
    }
  }
  .sppb-addon-content {
    z-index: 1;
    position: relative;
  }
  .button {
    background: #ff5400;
    color: #ffffff;
    font-family: 'Montserrat', sans-serif, Arial, sans-serif;
    font-weight: 400;
    letter-spacing: 0.5px;
  }
  .tz-form-group .chzn-container-single {
    .chzn-single {
      div > b {
        background: none;
        &:after {
          content: '\f0d7';
          font-family: FontAwesome;
          color: #ffffff;
        }
      }
    }
    .chzn-drop {
      background: rgba(255, 255, 255, 1);
      border-color: rgba(255, 255, 255, 1);
      .chzn-results {
        color: #222222;
      }
    }
  }
  .chzn-container-active.chzn-with-drop .chzn-single div b {
    &:after {
      content: '\f0d8';
    }
  }
}

/*------ Slide 4 -------*/
.tz__bg--gradient {
  .tp-dottedoverlay {
    background: rgba(0, 0, 0, 0.4);
    //background: transparent;
    &:after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 4;
      //opacity: 0.4;
      background: rgba(255, 84, 0, 0.4);
      background: -moz-linear-gradient(-140deg, rgba(255, 84, 0, 0.4) 0%, rgba(255, 84, 0, 0.4) 0%, rgba(255, 84, 0, 0.4) 0%, rgba(48, 109, 113, 0.4) 100%);
      background: -webkit-gradient(left bottom, right top, color-stop(0%, rgba(255, 84, 0, 0.4)), color-stop(0%, rgba(255, 84, 0, 0.4)), color-stop(0%, rgba(255, 84, 0, 0.4)), color-stop(100%, rgba(48, 109, 113, 0.4)));
      background: -webkit-linear-gradient(-140deg, rgba(255, 84, 0, 0.4) 0%, rgba(255, 84, 0, 0.4) 0%, rgba(255, 84, 0, 0.4) 0%, rgba(48, 109, 113, 0.4) 100%);
      background: -o-linear-gradient(-140deg, rgba(255, 84, 0, 0.4) 0%, rgba(255, 84, 0, 0.4) 0%, rgba(255, 84, 0, 0.4) 0%, rgba(48, 109, 113, 0.4) 100%);
      background: -ms-linear-gradient(-140deg, rgba(255, 84, 0, 0.4) 0%, rgba(255, 84, 0, 0.4) 0%, rgba(255, 84, 0, 0.4) 0%, rgba(48, 109, 113, 0.4) 100%);
      background: linear-gradient(-140deg, rgba(255, 84, 0, 0.4) 0%, rgba(255, 84, 0, 0.4) 0%, rgba(255, 84, 0, 0.4) 0%, rgba(48, 109, 113, 0.4) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff5400', endColorstr='#306d71', GradientType=1);
    }
  }
  .tparrows.preview1:after {
    background: #222222;
    color: #ffffff;
  }
  .tparrows.preview1:hover:after {
    background: #ff5400;
    color: #222222;
  }
}

/*------ Gradient 50% -------*/
.tz-bg-gradient-50 {
  .sppb-row-overlay {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(255, 84, 0, 0.4);
      background: -moz-linear-gradient(-140deg, rgba(255, 84, 0, 0.3) 30%, rgba(48, 109, 113, 0.4) 70%);
      background: -webkit-gradient(left top, right bottom, color-stop(30%, rgba(255, 84, 0, 0.3)), color-stop(70%, rgba(48, 109, 113, 0.4)));
      background: -webkit-linear-gradient(-140deg, rgba(255, 84, 0, 0.3) 30%, rgba(48, 109, 113, 0.4) 70%);
      background: -o-linear-gradient(-140deg, rgba(255, 84, 0, 0.3) 30%, rgba(48, 109, 113, 0.4) 70%);
      background: -ms-linear-gradient(-140deg, rgba(255, 84, 0, 0.3) 30%, rgba(48, 109, 113, 0.4) 70%);
      background: linear-gradient(-140deg, rgba(255, 84, 0, 0.3) 30%, rgba(48, 109, 113, 0.4) 70%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff5400', endColorstr='#306d71', GradientType=1);
    }
  }
}

/*------ Tab Module -----*/
.tz-addon-tab-module {
  .nav {
    text-align: center;
    position: relative;
    padding-bottom: 30px;
    margin-bottom: 30px;
    li {
      margin: 0;
      background: transparent;
      padding: 0;
      a {
        font-size: 18px;
        font-weight: 700;
        font-family: 'Montserrat', sans-serif, Arial, sans-serif;
        color: #222222;
        text-transform: uppercase;
        line-height: 25px;
        padding: 10px 35px;
        background: #ffffff;
        .transition(all 0.3s linear 0s);
        border-right: 1px solid rgba(0, 0, 0, 0.1);
        @media (max-width: 480px) {
          font-size: 12px;
          padding: 5px 20px;
        }
      }
      &:hover,
      &.active {
        a {
          color: #ffffff;
          background: #ff5400;
        }
      }
      &.open > a, &.open > a:hover {
        background: #ff5400;
        border-right-color: rgba(0, 0, 0, 0.1);
      }
      &:first-child {
        a {
          -webkit-border-radius: 3px 0 0 3px;
          -moz-border-radius: 3px 0 0 3px;
          border-radius: 3px 0 0 3px;
        }
      }
      &:last-child {
        a {
          -webkit-border-radius: 0 3px 3px 0;
          -moz-border-radius: 0 3px 3px 0;
          border-radius: 0 3px 3px 0;
          border-right: none;
        }
      }
    }
    &:after {
      position: absolute;
      content: "";
      bottom: 0;
      left: 50%;
      width: 60px;
      height: 4px;
      border-top: 1px solid #ff5400;
      border-bottom: 1px solid #ff5400;
      margin-left: -30px;
    }
  }
  .tz-desc {
    max-width: 720px;
    margin: auto;
    text-align: center;
    font-size: 15px;
    line-height: 22px;
    //color: #ffffff;
    margin-bottom: 60px;
  }
}

.tz-block--container {
  > .sppb-addon-content {
    max-width: 1200px;
    padding: 0;
    margin: auto;
  }
}

.tz_show_field_vehicle_type {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: 'Muli', sans-serif, Arial, sans-serif;
  > * {
    width: 20%;
    margin-right: 5%;
    margin-bottom: 20px;
  }
  .title {
    text-align: center;
    font-size: 13px;
    color: #565656;
    font-weight: 400;
    margin-top: 5px;
    display: block;
  }
  .tz_field_vehicle {
    &:hover {
      a {
        color: #ff5400;
      }
    }
  }
}

.tz_search_by_make {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  //justify-content: center;
  //text-align: center;
  font-family: 'Muli', sans-serif, Arial, sans-serif;
  > * {
    width: 30%;
    margin-right: 3%;
    margin-bottom: 15px;
  }
  .logo_make {
    margin-right: 15px;
  }
  a {
    font-size: 13px;
    font-weight: 400;
  }
}



