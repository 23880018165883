@media (max-width: 1199px) {
  body {
    font-size: 13px;
    p, a {
      font-size: 13px;
    }
  }

  .plazart-megamenu .level0 > li > a {
    padding: 45px 15px;
  }

  .tz-fix-position-filter {
    margin-left: -30px;
  }

  .tz__social__share.tz__social__share--1 ul li a i {

  }

  .plazart-mainnav,
  .tz__menu .tz__block__menu {
    //position: relative;
  }
}

@media (max-width: 991px) {
  .tz-fix-position-filter {
    margin-left: -15px;
    width: 250px;
    top: 50px;
  }

  .tz-filter select {
    width: 100%;
    min-width: 0;
    padding: 5px;
  }

  .tz-filter-vertical {
    form {
      padding: 15px 20px 25px 20px;
      .chzn-container-single .chzn-single {
        padding: 5px 15px;
      }
      textarea, input {
        height: 35px;
        padding: 5px 15px;
      }
    }
    .tz-button {
      margin-top: 25px;
    }
  }

  .tz__masstop {
    ul li {
      //padding-left: 5px;
      //margin-left: 5px;
    }
    em {
      //padding-right: 0;
    }

  }

  .tz-container {
    .sppb-container-inner {
      //padding: 0;
    }
  }

  .plazart-megamenu .navbar-nav {
    margin: 0;
    display: block;
    text-align: left;
  }

  .plazart-mainnav .navbar-collapse {
    position: absolute;
    right: -9px;
    left: -9px;
    background: #f7f9fb;
    z-index: 9999;
    top: 100%;
    padding: 0 9px 10px;
    border-top: 1px solid rgba(0, 0, 0, 0.04);
    max-height: 350px;
    overflow-y: auto;
    @media (max-width: 767px) {
      right: 0;
      left: 0;
    }
  }

  .plazart-megamenu .level0 > li > a {
    padding: 10px 15px;
  }

  .plazart-megamenu .dropdown-menu {
    padding: 10px 15px;
  }

  .plazart-megamenu .mega-col-nav {
    background: #ffffff;
    .box-shadow(0 2px 3px rgba(0, 0, 0, 0.1));
    .mega-col-nav {
      .box-shadow(none);
    }
  }

  .plazart-megamenu [class*=" col-"].dropdown-menu .mega-col-nav {
    padding-top: 25px;
    .mega-col-nav {
      padding-top: 0;
    }
  }

  .plazart-megamenu [class^="col-"].dropdown-menu .mega-inner, .plazart-megamenu [class*=" col-"].dropdown-menu .mega-inner {
    padding: 0 !important;
  }

  .plazart-megamenu [class*=" col-"] .mega-nav > li:first-child, .plazart-megamenu [class*=" col-"].dropdown-menu .mega-nav > li:first-child {
    margin-top: 0 !important;
  }

  .plazart-mainnav {
    .navbar-header {
      //display: inline;
      position: absolute;
      top: 50%;
      .translate(0, -50%);
      right: 15px;
      z-index: 999;
      .navbar-toggle {
        padding: 0;
        margin: 0;
        font-size: 18px;
        color: #222222;
      }
    }
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .tz-footer-clear-both > .sppb-container-inner > .sppb-row .sppb-col-sm-6:nth-child(2n+1) {
    clear: both;
  }
}

@media (max-width: 767px) {
  .tz-fix-position-filter {
    position: relative;
    left: 0;
    .translate(0, 0);
    width: 100%;
    margin: 0;
    top: 0;
  }
}