//header 1
.tz__menu {
  background: #f7f9fb;

  > .container {
    position: relative;
    > .row {
      position: static;
    }
  }
  .tz__block__menu {
    position: static;
  }
}

.tz__menu__style--2 {
  background: transparent;
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 99999;
  .plazart-megamenu .level0 > li > a {
    color: #ffffff;
    @media (max-width: 991px) {
      color: #222222;
    }
  }
  > .container {
    width: auto;
    max-width: 1200px;
  }
  .plazart-mainnav {
    position: relative;
  }
  @media (max-width: 1199px) {
    > .container {
      padding: 0 15px;
    }
  }
  @media (max-width: 991px) {
    .plazart-mainnav {
      .navbar-header {
        right: 30px;
      }
      .navbar-collapse {
        left: 15px;
        right: 15px;
      }
    }
  }
  @media (max-width: 767px) {
    position: relative;
    .plazart-mainnav {
      .navbar-header {
        right: 15px;
        top: 25px;
        .navbar-toggle {
          color: #ffffff;
        }
      }
      .navbar-collapse {
        left: 0;
        right: 0;
      }
    }
  }
  .plazart-megamenu .mega-align-justify .mega-dropdown-menu {
    left: 0 !important;
    right: 0;
  }
}

.tz__menu__style--3 {
  position: absolute;
  top: 0;
  z-index: 999;
  width: 100%;
  background: transparent;
  .plazart-megamenu .level0 > li > a {
    color: #ffffff;
  }
  > .container {
    padding: 0;
  }
  .tz-container {
    margin: auto;
  }
  .tz__masstop {
    border: none;
    background: #222222 !important;
    color: #777777 !important;
    .sppb-section {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
  @media (max-width: 1199px) {
    .tz-container {
      margin: 0 15px;
    }
  }
  @media (max-width: 991px) {
    .plazart-mainnav .navbar-header .navbar-toggle {
      color: #ffffff;
    }

    .plazart-mainnav .navbar-collapse {
      right: -15px;
      left: -15px;
    }

    .plazart-megamenu .level0 > li > a {
      color: #222222;
    }
  }
  @media (max-width: 767px) {
    .tz__masstop .sppb-container-inner .sppb-container-inner {
      margin: 0;
    }
  }
}

.tz__menu__style--4 {
  position: absolute;
  top: 0;
  z-index: 999;
  width: 100%;
  background: rgba(0, 0, 0, 1);
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0) 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0, 0, 0, 0.1)), color-stop(100%, rgba(255, 255, 255, 0)));
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0) 100%);
  background: -o-linear-gradient(top, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0) 100%);
  background: -ms-linear-gradient(top, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#ffffff', GradientType=0);
  padding-bottom: 90px;
  .plazart-megamenu .level0 > li > a {
    color: #ffffff;
  }
  > .container {
    padding: 0;
  }
  .tz-container {
    margin: auto;
  }
  .tz__masstop {
    border-top: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(34, 34, 34, 0.2);
    .tz-container > .sppb-container-inner {
      @media (max-width: 767px) {
        padding: 0 15px;
      }
    }
  }
  * {
    //color: #fff;
  }
  a {
    color: #ff5400;
  }
  i {
    color: #222222;
  }
  @media (max-width: 767px) {
    position: relative;
    background: #222222;
    padding-bottom: 0;
    .plazart-mainnav .navbar-collapse{
      background: #222222;
    }
    .plazart-mainnav .navbar-header .navbar-toggle{
      color: #ffffff;
      i{
        color: #ffffff;
      }
    }
  }

}

.plazart-mainnav {
  min-height: 0;
  margin: 0;
  border: none;
  position: static;
  .navbar-collapse {
    padding: 0;
  }
}

.plazart-megamenu {
  .navbar-nav > li {
    display: block !important;
  }
  .navbar-nav {
    float: none;
    display: inline-block;
    margin-bottom: -5px;
    &:after {
      //content: none;
    }
  }
  .level0 > li {
    > a {
      > .caret {
        display: none !important;
      }
    }
    &:last-child {
      > a {
        padding-right: 0;
      }
    }
    &.active > a, &.current > a,
    &.open > a, > a:hover, > a:focus, > a:active {
      background: transparent;
      color: #ff5400;
    }
    > a {
      padding: 45px 25px;
      line-height: 20px;
      font-size: inherit;
      text-transform: uppercase;
      color: #222222;
      -webkit-transition: background 200ms linear, color 200ms linear;
      -moz-transition: background 200ms linear, color 200ms linear;
      -ms-transition: background 200ms linear, color 200ms linear;
      -o-transition: background 200ms linear, color 200ms linear;
      transition: background 200ms linear, color 200ms linear;
      font-weight: normal;
      @media (max-width: 767px) {
        font-size: 11px;
        line-height: 1.5;
        padding: 7px !important;
      }
    }
  }
  .mega-align-justify {
    .mega-dropdown-menu {
      left: 15px !important;
      right: 15px;
      width: auto;
    }

  }
  [class^="col-"], [class*=" col-"] {
    &.dropdown-menu {
      padding: 10px 30px 15px 30px;
    }
  }
  .dropdown-menu {
    padding: 10px 20px 10px 20px;
    border-top: 3px solid #ff5400;
    border-left: 0px solid #ff5400;
    border-right: 0px solid #ff5400;
    border-bottom: 0px solid #ff5400;
    .box-shadow(0px 2px 2px 0px rgba(0, 0, 0, 0.1));
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
  }
  .mega-col-module {
    .tz-filter-vertical {
      h3 {
        display: none;
      }
      form {
        padding: 5px 0 0;
        .box-shadow(none);
        @media (max-width: 991px) {
          margin-bottom: 30px !important;
          label {
            font-size: 11px;
          }
        }
      }
      a {
        border: 1px solid #dfdfdf !important;
        padding: 10px 15px !important;
      }
      .tz-filter {
        .chzn-container-single {
          display: inline-block !important;
          width: 49% !important;
        }
      }
      select {
        @media (max-width: 991px) {
          font-size: 12px;
        }
      }
    }
  }
}

//massTop
.tz__masstop {
  border-top: 3px solid #ff5400;
  em, a {
    color: #ff5400;
  }
  em {
    padding-right: 5px;
  }
  ul {
    margin: 0;
    li {
      padding-left: 15px;
      margin-left: 15px;
      padding-right: 0;
      margin-right: -5px;
      line-height: 25px;
      &:first-child {
        padding-left: 0;
        margin-left: 0;
      }
    }
  }
}

.tz__mastop__style--2 {
  border: none;
  @media (min-width: 992px) and(max-width: 1199px) {
    margin-top: 90px !important;
    .v-center {
      top: -45px;
    }
  }
  @media (max-width: 991px) and(min-width: 768px) {
    .v-center {
      left: -15px;
      .translate(0, -50%);
    }

    .col-sm-offset-3 {
      margin-left: 25% !important;
    }
  }

  @media (max-width: 767px) {

    .v-center {
      left: 0;
      .translate(0, 0);
      width: 100%;
      position: relative;
      top: 0;
      background: #ff5400;
    }

    .tz-image-logo {
      img {
        max-height: 60px;
      }
    }

    .col-sm-offset-3 {
      margin-left: 15px !important;
      padding: 0 !important;
      margin-right: 15px !important;
    }

    .sppb-container-inner .sppb-container-inner {
      margin: 0 !important;
    }

    &.tz__masstop {
      .sppb-container-inner {
        margin: 0 -15px;
        padding: 0;
        overflow: hidden;
      }
      ul li {
        font-size: 11px;
      }
    }

    .sppb-addon-content {
      font-size: 11px;
      p, a {
        font-size: 11px;
      }
    }

    margin: 0 -15px !important;
    padding: 0 15px !important;
  }

}

.v-center {
  position: absolute;
  top: 50%;
  left: 50%;
  .translate(-50%, -50%);
}

//Breadcurmb
.tz__block__page__title {
  padding: 49px 0 47px;
  background: rgba(0, 0, 0, 0.6);
  position: relative;
  @media (max-width: 767px) {
    padding: 35px 0 ;
  }
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.6);
    height: 100%;
  }
  h2 {
    color: #eaeaea;
    display: inline-block;
    font-size: 30px;
    margin: 0 70px 0 0;
    padding: 0 0 0 0;
    text-align: left;
    text-transform: uppercase;
    position: relative;
    @media (max-width: 767px) {
      font-size: 24px;
    }
    &:before {
      content: '';
      display: block;
      width: 30px;
      height: 1px;
      background: #ff5400;
      position: absolute;
      top: 50%;
      left: 100%;
      margin-top: -3px;
      margin-left: 30px;
    }
    &:after {
      content: '';
      display: block;
      width: 45px;
      height: 1px;
      background: #ff5400;
      position: absolute;
      top: 50%;
      left: 100%;
      margin-top: 4px;
      margin-left: 30px;
    }
  }
}

.tz__block__breadcrumbs {
  padding: 19px 0 24px;
  background: #ffffff;
  * {
    //color: #565656;
  }
  ul {
    margin: 0;
    li {
      padding: 0;
    }
  }
}

.style-flex {
  .sppb-row {
    display: flex;
    padding: 10px 0;
    flex-wrap: nowrap;
  }
  &.sppb-text-center {
    .sppb-row {
      align-items: center;

    }
  }
}